import { combineReducers } from '@reduxjs/toolkit';

import notificationReducer from '../../packages/notifications/store/notificationReducer';
import dashboardReducer from '../../packages/dashboard/store/dashboardReducer';
import authReducer from '../../packages/auth/store/authReducer';
import reportReducer from '../../packages/reports/store/reportReducer';
import paymentReducer from '../../packages/payments/store/paymentReducer';
import teamReducer from '../../packages/team/store/teamReducer';
//
export default combineReducers({
  authReducer,
  dashboardReducer,
  notificationReducer,
  reportReducer,
  paymentReducer,
  teamReducer,
});
