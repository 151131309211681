import { useState } from 'react';
// @mui
import {
  Collapse,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
// components
//
import Iconify from '../../../components/iconify/Iconify';

// ----------------------------------------------------------------------

const PAYMENT_OPTIONS = [
  {
    value: 'cash',
    title: 'CASH',
    icons: ['https://minimal-assets-api.vercel.app/assets/icons/ic_paypal.svg'],
  },
  {
    value: 'mpesa',
    title: 'M-PESA',
    icons: [
      'https://minimal-assets-api.vercel.app/assets/icons/ic_mastercard.svg',
      'https://minimal-assets-api.vercel.app/assets/icons/ic_visa.svg',
    ],
  }, 
  {
    value: 'insurance',
    title: 'INSURANCE',
    icons: [
      'https://minimal-assets-api.vercel.app/assets/icons/ic_mastercard.svg',
      'https://minimal-assets-api.vercel.app/assets/icons/ic_visa.svg',
    ],
  },
];

const OptionStyle = styled(Paper)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2),
  transition: theme.transitions.create('all'),
  border: `solid 1px ${theme.palette.divider}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
}));

// ----------------------------------------------------------------------

export default function PaymentMethods() {
  // const [show, setShow] = useState(false);

  const [method, setMethod] = useState('cash');
  const [mobileNumber, setMobileNumber] = useState('');
  // console.log('MOBILE:', mobileNumber);

  const handleChangeMethod = (event) => {
    if (method === 'cash') {
      // setShow(false);
    }
    setMethod(event.target.value);
  };

  return (
    <div>
      <Typography variant="subtitle1" sx={{ mb: 5 }}>
        Payment Method
      </Typography>

      <RadioGroup value={method} onChange={handleChangeMethod}>
        <Stack spacing={3}>
          {PAYMENT_OPTIONS.map((option) => {
            const { value, title, 
              // icons
             } = option;

            const hasChildren = value === 'mpesa';

            const isSelected = method === value;

            return (
              <OptionStyle
                key={title}
                sx={{
                  ...(isSelected && {
                    boxShadow: (theme) => theme.customShadows.z20,
                  }),
                  ...(hasChildren && { flexWrap: 'wrap' }),
                }}
              >
                <FormControlLabel
                  value={value}
                  control={<Radio checkedIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />} />}
                  label={
                    <Typography variant="subtitle2" sx={{ ml: 1 }}>
                      {title}
                    </Typography>
                  }
                  sx={{ py: 3, mx: 0 }}
                />

                {/* <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  sx={{ position: 'absolute', right: 20, top: 32 }}
                >
                  {icons.map((icon) => (
                    <Image key={icon} alt="logo card" src={icon} />
                  ))}
                </Stack> */}

                {hasChildren && (
                  <Collapse in={method === 'mpesa'} sx={{ width: 1 }}>
                    <TextField
                      fullWidth
                      label="Mobile Number"
                      value={mobileNumber}
                      sx={{ mb: 5 }}
                      onChange={(e) => setMobileNumber(e.target.value)}
                    />
                  </Collapse>
                )}
              </OptionStyle>
            );
          })}
        </Stack>
      </RadioGroup>
    </div>
  );
}
