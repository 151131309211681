import { useSnackbar } from 'notistack';

export const useNotify = () => {
  const { enqueueSnackbar } = useSnackbar();

  const notifySuccess = (msg) => {
    enqueueSnackbar(msg, 'success');
  };

  const notifyError = (msg) => {
    enqueueSnackbar(msg, 'error');
  };
  return {
    notifySuccess,
    notifyError,
  };
};
