import { Outlet, Navigate } from 'react-router-dom';
import T from 'prop-types';
import AuthService from './AuthService';

AuthGuard.propTypes = {
  children: T.any,
};

export default function AuthGuard({ children }) {
  if (!AuthService.isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }
  return children || <Outlet />;
}
