import { useRoutes } from 'react-router-dom';
import { AuthRoutes } from '../packages/auth/routes/authRoutes';
import { dashboardRoutes } from '../packages/dashboard/routes/dashboardRoutes';
import { invoiceRoutes } from '../packages/payments/routes/invoiceRoutes';

let systemRoutes = [];

systemRoutes = AuthRoutes;
systemRoutes.push(...dashboardRoutes);
systemRoutes.push(...invoiceRoutes);
export default function Router() {
  const routes = useRoutes(systemRoutes);
  return routes;
}
