import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { createTheme } from '@mui/material/styles';
// routes
import Router from './router';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

// ----------------------------------------------------------------------

export default function App() {
  const { darkMode } = useSelector((state) => state.dashboardReducer);
  console.log('DARK MODE:', darkMode);

  const lightTheme = createTheme();
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  const selectedTheme = darkMode ? darkTheme : lightTheme;
  return (
    <ThemeProvider theme={selectedTheme}>
      <HelmetProvider>
        <BrowserRouter>
          <ToastContainer theme="colored" autoClose={3000} />
          <ScrollToTop />
          <StyledChart />
          <Router />
        </BrowserRouter>
      </HelmetProvider>
    </ThemeProvider>
  );
}
