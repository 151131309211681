// component

import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: <DashboardIcon />,
  },
  {
    title: 'Send Money',
    path: '/dashboard/send-money',
    icon: <LinkOutlinedIcon />,
  },
  {
    title: 'Transactions',
    path: '/dashboard/transactions',
    icon: <PaidOutlinedIcon />,
  },
  // {
  //   title: 'Customers',
  //   path: '/dashboard/customers',
  //   icon: <PeopleOutlineIcon />,
  // },
  // {
  //   title: 'Reports',
  //   path: '/dashboard/reports',
  //   icon: <AnalyticsOutlinedIcon />,
  // },
  // {
  //   title: 'Settings',
  //   path: '/dashboard/reports',
  //   icon: <SettingsOutlinedIcon />,
  // },
];

export default navConfig;
