import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

const navConfig = [
  {
    title: 'Go Back',
    path: '/dashboard/app',
    icon: <ArrowBackIosNewOutlinedIcon />,
  },
  {
    title: 'Quotes',
    path: '/accounting/app',
    icon: <ReceiptOutlinedIcon />,
  },
  {
    title: 'Invoices',
    path: '/accounting/invoices',
    icon: <ReceiptOutlinedIcon />,
  },
  {
    title: 'Transactions',
    path: '/accounting/transactions',
    icon: <PaidOutlinedIcon />,
  },
];

export default navConfig;
