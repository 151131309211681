// /* eslint-disable */

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

// @mui
import { Typography, Stack, IconButton, InputAdornment, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { AUTH } from '../../../utils/urls';
import { registerSchema } from '../schemas/authSchemas';
import call from '../../../core/services/http';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleFormSubmit = async (values, actions) => {
    setIsLoading(true);
    const response = await call(`${AUTH}/register/`, values);

    if (response.status) {
      setIsLoading(false);
      actions.resetForm();
      navigate('/login', { replace: true });
      toast.success(response.message);
    } else {
      setIsLoading(false);
      toast.error(response.message);
    }
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      mobile_number: '',
      password: '',
      confirm_password: '',
      acceptedTos: '',
    },
    validationSchema: registerSchema,
    onSubmit: handleFormSubmit,
    isSubmitting: isLoading,
  });
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            name="first_name"
            label="First Name"
            value={values.first_name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.first_name && Boolean(errors.first_name)}
          />
          {touched.first_name && Boolean(errors.first_name) && (
            <Typography color="error" variant="body2">
              {errors.first_name}
            </Typography>
          )}
          <TextField
            name="last_name"
            value={values.last_name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.last_name && Boolean(errors.last_name)}
            label="Last Name"
          />
          {touched.last_name && Boolean(errors.last_name) && (
            <Typography color="error" variant="body2">
              {errors.last_name}
            </Typography>
          )}
          <TextField
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && Boolean(errors.email)}
            label="Email"
          />
          {touched.email && Boolean(errors.email) && (
            <Typography color="error" variant="body2">
              {errors.email}
            </Typography>
          )}
          <TextField
            name="mobile_number"
            value={values.mobile_number}
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            error={touched.mobile_number && Boolean(errors.mobile_number)}
            label="Mobile Number"
          />
          {touched.mobile_number && Boolean(errors.mobile_number) && (
            <Typography color="error" variant="body2">
              {errors.mobile_number}
            </Typography>
          )}

          <TextField
            name="password"
            label="Password"
            onChange={handleChange}
            value={values.password}
            onBlur={handleBlur}
            error={touched.password && Boolean(errors.password)}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {touched.password && Boolean(errors.password) && (
            <Typography color="error" variant="body2">
              {errors.password}
            </Typography>
          )}
          <TextField
            name="confirm_password"
            label="Confirm Password"
            onChange={handleChange}
            value={values.confirm_password}
            onBlur={handleBlur}
            error={touched.confirm_password && Boolean(errors.confirm_password)}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {touched.confirm_password && Boolean(errors.confirm_password) && (
            <Typography color="error" variant="body2">
              {errors.confirm_password}
            </Typography>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox />}
            name="acceptedTos"
            onChange={handleChange}
            value={values.acceptedTos}
            onBlur={handleBlur}
            error={touched.acceptedTos && Boolean(errors.acceptedTos)}
            label="Agree to terms and conditions"
          />
          {touched.acceptedTos && Boolean(errors.acceptedTos) && (
            <Typography color="error" variant="body2">
              {errors.acceptedTos}
            </Typography>
          )}
        </Stack>
        <LoadingButton
          loading={isSubmitting}
          disabled={isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Create Account
        </LoadingButton>
      </form>
    </>
  );
}
