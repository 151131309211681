import Login from '../view/Login';
import Register from '../view/Register';

export const AuthRoutes = [
  {
    path: 'register',
    element: <Register />,
  },
  {
    path: 'login',
    element: <Login />,
  },
];
