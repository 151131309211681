// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
// sections
import { LoginForm } from '../../../sections/auth';
import { Image, Page } from '../../../components';
import { APP_NAME } from '../../../core/environment/environment';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  // const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Page title="Login">
        <StyledRoot>
          <HeaderStyle>
            <Logo />
            {/* {smUp && (
              <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                Don’t have an account? {''}
                <Link variant="subtitle2" component={RouterLink} to={'/register'}>
                  Get started
                </Link>
              </Typography>
            )} */}
          </HeaderStyle>

          {mdUp && (
            <StyledSection>
              {/* <Typography variant="h3" sx={{ px: 5, mt: 15, mb: 5 }}>
                Hi, Welcome Back
              </Typography> */}
              <Image
                visibleByDefault
                disabledEffect
                alt="login"
                src="https://pay2me.africa/assets/images/frontend/login/65af5911f07d31705990417.png"
              />
            </StyledSection>
          )}

          {/* Personal budgeting is the secret to financial freedom. Start your journey today. */}
          <Container maxWidth="sm">
            <StyledContent>
              <Typography variant="h4" gutterBottom sx={{ py: 3 }}>
                {APP_NAME}
              </Typography>

              {/* <Typography variant="body2" sx={{ mb: 5 }}>
                Don’t have an account? {''}
                <Link to="/register" underline="hover" variant="subtitle2">
                  Get started
                </Link>
              </Typography> */}
              <LoginForm />
            </StyledContent>
          </Container>
        </StyledRoot>
      </Page>
    </>
  );
}
