const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const AUTH = `${BASE_URL}/auth`;
export const DASHBOARD = `${BASE_URL}/dashboard/`;
export const LOANS = `${BASE_URL}/loans/`;
export const CATEGORIES = `${BASE_URL}/categories/`;
export const BUDGETS = `${BASE_URL}/budgets/`;
export const CUSTOMERS = `${BASE_URL}/customers/`;
export const STAFF = `${BASE_URL}/staff/`;
export const REMINDER = `${BASE_URL}/reminders/`;
export const NOTIFICATIONS = `${REMINDER}notifications/`;
