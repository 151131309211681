
export const GET_REPORT_REQUEST = 'REPORTS/GET_REPORT_REQUEST';
export const GET_REPORT_SUCCESS = 'REPORTS/GET_REPORT_SUCCESS';
export const GET_REPORT_ERROR = 'REPORTS/GET_REPORT_ERROR';
// export const CREATE_ASSET_REQUEST = 'ASSETS/CREATE_ASSET_REQUEST';
// export const CREATE_ASSET_SUCCESS = 'ASSETS/CREATE_ASSET_SUCCESS';
// export const CREATE_ASSET_ERROR = 'ASSETS/CREATE_ASSET_ERROR';
// export const UPDATE_ASSET_REQUEST = 'ASSETS/UPDATE_ASSET_REQUEST';
// export const UPDATE_ASSET_SUCCESS = 'ASSETS/UPDATE_ASSET_SUCCESS';
// export const UPDATE_ASSET_ERROR = 'ASSETS/UPDATE_ASSET_ERROR';
// export const DELETE_ASSET_REQUEST = 'ASSETS/DELETE_ASSET_REQUEST';
// export const DELETE_ASSET_SUCCESS = 'ASSETS/DELETE_ASSET_SUCCESS';
// export const DELETE_ASSET_ERROR = 'ASSETS/DELETE_ASSET_ERROR';
