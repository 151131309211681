import { toast } from 'react-toastify';

import {
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_ERROR,
  GET_WALLET_BALANCE_REQUEST,
  GET_WALLET_BALANCE_SUCCESS,
  GET_WALLET_BALANCE_ERROR,
  TOGGLE_DARK_MODE,
} from './dashboardActionTypes';
import dashboardConstants from './dashboardConstants';
import call from '../../../core/services/http';

const toastrError = (msg) => {
  toast.error(msg);
};

export const getDashboardData = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_DASHBOARD_REQUEST,
      isLoading: true,
    });
    const response = await call('get', dashboardConstants.DASHBOARD);
    if (response.data.status) {
      dispatch({
        type: GET_DASHBOARD_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_DASHBOARD_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_DASHBOARD_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_DASHBOARD_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getWalletBalance = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_WALLET_BALANCE_REQUEST,
      isLoading: true,
    });
    const response = await call('get', dashboardConstants.WALLET_BALANCE);
    if (response.data.status) {
      dispatch({
        type: GET_WALLET_BALANCE_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_WALLET_BALANCE_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_WALLET_BALANCE_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_WALLET_BALANCE_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

export const toggleDarkMode = () => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_DARK_MODE,
    });
  } catch (error) {
    console.log('ERROR:', error);
  }
};
