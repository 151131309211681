import { useCallback, useEffect } from 'react';
// @mui
import { Grid, Container, Typography, Stack } from '@mui/material';
import useIsMountedRef from 'use-is-mounted-ref';
import { useDispatch, useSelector } from 'react-redux';
// sections
import { AppWidgetSummary, JobChartData } from '../../../sections/@dashboard/app';
import { Page } from '../../../components';
import { getDashboardData, getWalletBalance } from '../store/dashboardActions';
import { fCurrency } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

export default function Dashboard() {
  // const theme = useTheme();
  const dispatch = useDispatch();
  const { graphData, dashboardData, walletBalances } = useSelector((state) => state.dashboardReducer);

  const isMountedRef = useIsMountedRef();

  const getStatistics = useCallback(async () => {
    if (isMountedRef.current) {
      dispatch(getDashboardData());
    }
  }, [isMountedRef, dispatch]);

  useEffect(() => {
    getStatistics();
  }, [getStatistics]);

  useEffect(() => {
    dispatch(getWalletBalance());
  }, [dispatch]);

  return (
    <>
      <Page title="Dashboard">
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Dashboard
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Wallet Balance"
                total={fCurrency(walletBalances?.currentBalance)}
                color="success"
                icon={'material-symbols:person-book'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} sx={{ textDecoration: 'none' }}>
              <AppWidgetSummary
                title="Completed Transactions"
                total={fCurrency(dashboardData?.completed_transactions)}
                color="info"
                icon={'uil:money-insert'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} sx={{ textDecoration: 'none' }}>
              <AppWidgetSummary
                title="Pending Transactions"
                total={dashboardData?.pending_transactions}
                color="warning"
                icon={'ph:drop-half-bottom-fill'}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ textDecoration: 'none' }}>
              <AppWidgetSummary
                title="Failed Transactions"
                total={dashboardData?.failed_transactions}
                color="error"
                icon={'ic:outline-watch-later'}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <JobChartData
                title="Payment Statistics"
                subheader="Payment insights this year"
                chartLabels={(graphData && graphData?.chart_labels) || []}
                chartData={
                  (graphData &&
                    graphData?.chart_data?.map(({ status, count }) => {
                      const items = ['area', 'line'];
                      const type = items[Math.floor(Math.random() * items.length)];
                      return {
                        name: status,
                        type,
                        fill: (type === 'area' && 'gradient') || 'solid',
                        data: count.length === 0 ? [0] : count,
                      };
                    })) ||
                  []
                }
              />
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4}>
              <AppCurrentVisits
                title="Cost Per Category"
                chartData={(costPerClass && costPerClass) || []}
                chartColors={[
                  theme.palette.primary.main,
                  theme.palette.info.main,
                  theme.palette.warning.main,
                  theme.palette.error.main,
                ]}
              />
            </Grid> */}

            <Grid item xs={12} md={12} lg={12}>
              <Stack>{/* <RecentLoans recentLoans={recentLoans} /> */}</Stack>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
