import {
  AUTH_API_REQUEST,
  AUTH_API_SUCCESS,
  AUTH_API_FAILED,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
} from './authActionTypes';

const initialState = {
  isLoading: false,
  isSuccess: false,
  isLoggedIn:false,
  message: '',
};

export default function authReducer(state = initialState, action) {
  const { type, isLoading, isLoggedIn,isSuccess, message } = action;

  switch (type) {
    case AUTH_API_REQUEST:
      return {
        isLoading,
        isLoggedIn,
      };
    case AUTH_API_SUCCESS:
      return {
        isLoading,
        isLoggedIn,
        message,
      };
    case AUTH_API_FAILED:
      return {
        isLoading,
        isLoggedIn,
        message,
      };
    case CHANGE_PASSWORD_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case CHANGE_PASSWORD_FAILED:
      return {
        isLoading,
        isSuccess,
        message,
      };
    default: {
      return state;
    }
  }
}
