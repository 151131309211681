import { Navigate } from 'react-router-dom';
import DashboardLayout from '../../../layouts/dashboard/DashboardLayout';
import accountingConfig from '../../../layouts/dashboard/nav/accountingConfig';
import Transactions from '../view/Transactions';

export const invoiceRoutes = [
  {
    path: '/transactions',
    element: <DashboardLayout navConfig={accountingConfig} />,
    children: [
      { element: <Navigate to="/transactions/app" />, index: true },
      { path: 'transactions', element: <Transactions /> },
    ],
  },
];
