import { toast } from 'react-toastify';
import reportConstants from '../store/reportConstants';
import call from '../../../core/services/http';

const toastrError = (msg) => {
  toast.error(msg);
};

export const generateAssetMasterReport = async () => {
  try {
    const response = await call('get', reportConstants.GENERATE_MASTER_ASSET_REPORTS);
    if (response.status === undefined) {
      const blob = new Blob([response], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      // link.href = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      // eslint-disable-next-line no-useless-concat
      link.download = 'AssetsMasterReport' + '.csv';
      link.click();

      // Clean up the temporary objects
      window.URL.revokeObjectURL(link);
      link.remove();
    } else {
      toastrError(response.message);
    }
  } catch (error) {
    toastrError('An error occurred while generating report');
    console.log('There was an error', error);
  }
};
