import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { Stack, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { FormProvider } from '../../../components/hook-form';

import { fDateYMD } from '../../../utils/formatTime';
import { filterTransactions } from '../store/paymentActions';

TransactionFilter.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function TransactionFilter({ open, handleClose }) {
  const dispatch = useDispatch();

  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  const filterSchema = Yup.object().shape({});

  const defaultValues = {
    startDate: fDateYMD(selectedStartDate) || '',
    endDate: fDateYMD(selectedEndDate) || '',
  };

  const handleStartDateChange = (newValue) => {
    setValue('startDate', fDateYMD(newValue));
    setSelectedStartDate(newValue);
  };
  const handleEndDateChange = (newValue) => {
    setValue('endDate', fDateYMD(newValue));
    setSelectedEndDate(newValue);
  };

  const methods = useForm({
    resolver: yupResolver(filterSchema),
    defaultValues,
  });

  const {
    // reset,
    // watch,
    // control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const values = watch();

  const onSubmit = async (data) => {
    const payload = {
      startDate: data.startDate,
      endDate: data.endDate,
    };
    dispatch(filterTransactions(payload));
    handleClose();
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      fullWidth
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Advanced Filters</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Stack spacing={2}>
            <DatePicker
              label="Start Date"
              value={selectedStartDate}
              format="yyyy-MM-dd"
              onChange={handleStartDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label="End Date"
              value={selectedEndDate}
              format="yyyy-MM-dd"
              onChange={handleEndDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Filter
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
