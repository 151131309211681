import { LoadingButton } from '@mui/lab';
import { Box, MenuItem, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { categoryReportSchema } from '../schemas';
import { FormProvider, RHFSelect } from '../../../components/hook-form';
import reportConstants from '../store/reportConstants';
import call from '../../../core/services/http';

const toastrError = (msg) => {
  toast.error(msg);
};

export default function CategoryReportForm() {
  const dispatch = useDispatch();
  const { auditCategories } = useSelector((state) => state.categoryReducer);

  const generateCategoryAssetReport = async (payload) => {
    try {
      const response = await call('post', reportConstants.GENERATE_CLASS_REPORTS, payload);
      if (response.status === undefined) {
        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        // link.href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        // eslint-disable-next-line no-useless-concat
        link.download = 'CategoryAssetsReport' + '.csv';
        link.click();

        // Clean up the temporary objects
        window.URL.revokeObjectURL(link);
        link.remove();
      } else {
        toastrError(response.message);
      }
    } catch (error) {
      toastrError('An error occurred while generating report');
      console.log('There was an error', error);
    }
  };

  const defaultValues = {
    locationId: '',
  };

  const methods = useForm({
    resolver: yupResolver(categoryReportSchema),
    defaultValues,
  });

  const {
    // reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    const payload = {
      class_id: data.categoryId,
    };
    generateCategoryAssetReport(payload);
  };

  // useEffect(() => {
  //   // dispatch(getAuditCategories({}));
  // }, [dispatch]);
  return (
    <Box
      sx={{
        padding: 3,
        marginTop: 3,
        borderRadius: 1,
      }}
    >
      <Stack spacing={3}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <TextField fullWidth label="Expiration date" placeholder="MM/YY" />

          <TextField fullWidth label="Cvv" />
        </Stack> */}
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <RHFSelect
              fullWidth
              name="categoryId"
              label="Category"
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            >
              {auditCategories?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </RHFSelect>
          </Stack>

          <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
            {/* <Button color="inherit" variant="outlined">
            Cancel
          </Button> */}
            <LoadingButton type="submit" variant="contained" disabled={isSubmitting} loading={isSubmitting}>
              Download
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Stack>
    </Box>
  );
}
