// import PropTypes from 'prop-types';
// import { useMemo } from 'react';
// // @mui
// import { CssBaseline } from '@mui/material';
// import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
// //
// import palette from './palette';
// import shadows from './shadows';
// import typography from './typography';
// import GlobalStyles from './globalStyles';
// import customShadows from './customShadows';
// import componentsOverride from './overrides';

// // ----------------------------------------------------------------------

// ThemeProvider.propTypes = {
//   children: PropTypes.node,
// };

// export default function ThemeProvider({ children }) {
//   const themeOptions = useMemo(
//     () => ({
//       palette,
//       shape: { borderRadius: 6 },
//       typography,
//       shadows: shadows(),
//       customShadows: customShadows(),
//     }),
//     []
//   );

//   const theme = createTheme(themeOptions);
//   theme.components = componentsOverride(theme);

//   return (
//     <StyledEngineProvider injectFirst>
//       <MUIThemeProvider theme={theme}>
//         <CssBaseline />
//         <GlobalStyles />
//         {children}
//       </MUIThemeProvider>
//     </StyledEngineProvider>
//   );
// }
import PropTypes from 'prop-types';
import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider as MUIThemeProvider, StyledEngineProvider } from '@mui/material/styles';
// hooks
import useSettings from '../hooks/useSettings';
//
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const { themeMode, themeDirection } = useSettings();
  const isLight = themeMode === 'dark';

  const themeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight, themeDirection]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
