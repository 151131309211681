import {configureStore} from "@reduxjs/toolkit";
import logger from 'redux-logger'

import RootReducer from "./reducer/RootReducer";


const env=  process.env.NODE_ENV
const store = configureStore({
    reducer: RootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(env==='development' ? logger: []),
})
export default store;
