import { fDateYMD } from '../../../utils/formatTime';

export default {
  GET_INVOICES: ({ page = 1, rowsPerPage = 10 }) => `payments/payment-links/?page=${page}&page_size=${rowsPerPage}`,
  GET_TRANSACTIONS: ({ page = 1, rowsPerPage = 100 }) => `pallife/transactions/?page=${page}&page_size=${rowsPerPage}`,
  SEARCH_TRANSACTIONS: ({ page = 1, rowsPerPage = 100, searchTerm }) =>
    `pallife/transactions/?page=${page}&page_size=${rowsPerPage}&search_term=${searchTerm}`,
  GET_JOB_INVOICES: ({ jobId, page = 1, rowsPerPage = 10 }) =>
    `payments/invoices/?page=${page}&page_size=${rowsPerPage}&job_id=${jobId}`,
  GET_JOB_QUOTES: ({ jobId, page = 1, rowsPerPage = 10 }) =>
    `payments/quotations/?page=${page}&page_size=${rowsPerPage}&job_id=${jobId}`,
  GET_INVOICE: (id) => `payments/invoices/${id}/`,
  TRANSFER_ASSET: (id) => `assets/${id}/transfer/`,
  CREATE_INVOICE: 'payments/invoices/',
  UPDATE_QUOTE: (id) => `payments/quotations/${id}/`,
  UPDATE_QUOTE_STATUS: (id) => `payments/quotations/${id}/status-update/`,
  DELETE_QUOTATION: (id) => `payments/quotations/${id}/`,
  GET_EXCHANGE_RATES: 'pallife/exchange-rates/',
  GET_INCOME_SOURCES: 'pallife/income-sources/',
  GET_FUND_PURPOSES: 'pallife/fund-purposes/',
  GET_DOCUMENT_TYPES: 'pallife/document-types/',
  GET_MOMO: 'pallife/momo/',
  GET_OCCUPATIONS: 'pallife/occupations/',
  GET_PAYMENT_MODES: 'pallife/payment-modes/',
  GET_BANKS: 'pallife/banks/',
  GET_RELATIONSHIPS: 'pallife/relationships/',
  GET_CUSTOMERS: 'pallife/customers/',
  FILTER_TRANSACTIONS: ({
    page = 1,
    rowsPerPage = 10,
    startDate,
    endDate,
  }) =>
    `pallife/transactions/?page=${page}&page_size=${rowsPerPage}&start_date=${fDateYMD(startDate)}&end_date=${fDateYMD(
      endDate
    )}`,
  // `${startDate !== '' ? `&start_date=${fDateYMD(startDate)}` : ''}` +
  // `${endDate !== '' ? `&end_date=${fDateYMD(endDate)}` : ''}` +
  // `${barcodeNumber !== '' ? `&barcode_number=${barcodeNumber}` : ''}`,
  SEND_MONEY: 'pallife/send-money/',
};
