import {
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_ERROR,
  GET_WALLET_BALANCE_REQUEST,
  GET_WALLET_BALANCE_SUCCESS,
  GET_WALLET_BALANCE_ERROR,
  TOGGLE_DARK_MODE,
} from './dashboardActionTypes';

const initialState = {
  status: false,
  message: '',
  isLoading: false,
  graphData: {},
  dashboardData: {},
  walletBalances: {},
  darkMode: false,
};

export default function dashboardReducer(state = initialState, action) {
  const { type, payload, isLoading, message } = action;

  switch (type) {
    case GET_DASHBOARD_REQUEST:
      return {
        isLoading,
      };
    case GET_DASHBOARD_SUCCESS:
      return {
        isLoading,
        dashboardData: payload.data,
        graphData: payload.data.graph_data,
      };
    case GET_DASHBOARD_ERROR:
      return {
        isLoading,
        message,
      };
    case GET_WALLET_BALANCE_REQUEST:
      return {
        isLoading,
      };
    case GET_WALLET_BALANCE_SUCCESS:
      return {
        isLoading,
        walletBalances: payload.data[1],
      };
    case GET_WALLET_BALANCE_ERROR:
      return {
        isLoading,
        message,
      };
    case TOGGLE_DARK_MODE:
      return {
        ...state,
        darkMode: !state.darkMode,
      };
    default: {
      return state;
    }
  }
}
