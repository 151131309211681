import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SendIcon from '@mui/icons-material/Send';

// @mui
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Card, Stack, Container, CardHeader, Typography, Grid, InputAdornment, MenuItem } from '@mui/material';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
import { Page } from '../../../components';
import {
  getBanks,
  getCustomers,
  getDocumentTypes,
  getExchangeRates,
  getFundsPurposes,
  getIncomeSources,
  getMobileMoneyProviders,
  getOccupations,
  getPaymentModes,
  sendMoneyRequest,
} from '../store/paymentActions';

const currencyCodes = [
  { code: 'USD', label: 'US Dollar' },
  { code: 'KES', label: 'Kenyan Shilling' },
  // { code: 'UGX', label: 'Ugandan Shilling' },
];
const GENDER = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
];
export default function SendMoney() {
  const dispatch = useDispatch();
  const {
    isLoading,
    exchangeRates,
    incomeSources,
    fundPurposes,
    documentTypes,
    occupations,
    paymentModes,
    banks,
    momo,
    // customers,
  } = useSelector((state) => state.paymentReducer);

  const [senderCurrency, setSenderCurrency] = useState('USD');
  const [receiverCurrency, setReceiverCurrency] = useState('KES');
  const [senderAmount, setSenderAmount] = useState('');
  const [receiverAmount, setReceiverAmount] = useState('');
  const [exchangeRate, setExchangeRate] = useState(exchangeRates?.exchangeRate || null);
  const [paymentmode, setPaymentMode] = useState('');
  const [dataFetched, setDataFetched] = useState(false);

  const requestSchema = Yup.object().shape({
    senderCustomerType: Yup.string().required(''),
    senderFirstName: Yup.string().required(''),
    senderMiddleName: Yup.string(),
    senderLastName: Yup.string().required(''),
    senderDob: Yup.string().required(''),
    senderDocumentType: Yup.string().required(''),
    senderDocumentNumber: Yup.string().required(''),
    senderCountryCode: Yup.string().required(''),
    senderPhysicalAddress: Yup.string().required(''),
    senderEmail: Yup.string().required(''),
    senderPhonenumber: Yup.string().required(''),
    senderOccupation: Yup.string().required(''),
    receiverCustomerType: Yup.string().required(''),
    receiverCompanyName: Yup.string().required(''),
    receiverFirstName: Yup.string().required(''),
    receiverMiddleName: Yup.string(),
    receiverLastName: Yup.string().required(''),
    receiverDob: Yup.string().required(''),
    receiverGender: Yup.string(),
    receiverDocumentType: Yup.string().required(''),
    receiverDocumentNumber: Yup.string().required(''),
    receiverCountryCode: Yup.string().required(''),
    receiverPhysicalAddress: Yup.string().required(''),
    receiverEmail: Yup.string().required(''),
    receiverPhonenumber: Yup.string().required(''),
    receiverOccupation: Yup.string().required(''),
    receiverPaymentMode: Yup.string().required(''),
    receiverBankCode: Yup.string(),
    receiverBillerCode: Yup.string(),
    receiverAccountName: Yup.string(),
    receiverAccountNumber: Yup.string(),
    senderCurrency: Yup.string().required(''),
    receiverCurrency: Yup.string().required(''),
    receiverExchangeRate: Yup.string().required(''),
    senderAmount: Yup.string().required(''),
    receiverAmount: Yup.string().required(''),
    fundPurpose: Yup.string().required(''),
    incomeSource: Yup.string().required(''),
    remarks: Yup.string().required(''),
  });

  const defaultValues = {
    senderCustomerType: 'INDIVIDUAL',
    senderFirstName: '',
    senderMiddleName: '',
    senderLastName: '',
    senderDob: '',
    senderDocumentType: '',
    senderDocumentNumber: '',
    senderCountryCode: '',
    senderPhysicalAddress: '',
    senderEmail: '',
    senderPhonenumber: '',
    senderOccupation: '',
    receiverCustomerType: 'INDIVIDUAL',
    receiverCompanyName: '',
    receiverFirstName: '',
    receiverMiddleName: '',
    receiverLastName: '',
    receiverDob: '',
    receiverGender: '',
    receiverDocumentType: '',
    receiverDocumentNumber: '',
    receiverCountryCode: '',
    receiverPhysicalAddress: '',
    receiverEmail: '',
    receiverPhonenumber: '',
    receiverOccupation: '',
    receiverPaymentMode: paymentmode || '',
    receiverBankCode: '',
    receiverBillerCode: '',
    receiverAccountName: '',
    receiverAccountNumber: '',
    senderCurrency: senderCurrency || '',
    receiverCurrency: receiverCurrency || '',
    receiverExchangeRate: exchangeRates?.exchangeRate || exchangeRate || '',
    senderAmount: senderAmount || '',
    receiverAmount: receiverAmount || '',
    fundPurpose: '',
    incomeSource: '',
    remarks: '',
  };
  const methods = useForm({
    resolver: yupResolver(requestSchema),
    defaultValues,
  });

  const {
    setValue,
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const values = watch();
  // console.log('VALUES:', values);

  useEffect(() => {
    // Calculate receiver amount when sender amount or exchange rate changes
    if (senderAmount && exchangeRates?.exchangeRate) {
      setExchangeRate(exchangeRates?.exchangeRate);
      setValue('receiverExchangeRate', exchangeRates?.exchangeRate);
      const calculatedReceiverAmount = parseFloat(senderAmount) * exchangeRates?.exchangeRate;
      const formattedReceiverAmount = calculatedReceiverAmount.toFixed(2);
      setReceiverAmount(formattedReceiverAmount);

      setValue('receiverAmount', formattedReceiverAmount);
    } else {
      setReceiverAmount('');
      setValue('receiverAmount', '');
    }
  }, [senderAmount, exchangeRate, exchangeRates?.exchangeRate, setValue]);
  const fetchData = useCallback(async () => {
    try {
      await Promise.all([
        dispatch(getExchangeRates()),
        dispatch(getIncomeSources()),
        dispatch(getFundsPurposes()),
        dispatch(getDocumentTypes()),
        dispatch(getOccupations()),
        dispatch(getPaymentModes()),
        dispatch(getBanks()),
        dispatch(getMobileMoneyProviders()),
        dispatch(getCustomers()),
        setDataFetched(true),
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error if needed
    }
  }, [dispatch]);
  const handleSendMoney = async (data) => {
    console.log('API DATA:', data);
    const payload = data;
    dispatch(sendMoneyRequest(payload));
    reset();
    setTimeout(() => {
      fetchData();
    }, 2000);
  };

  useEffect(() => {
    if (!dataFetched) {
      fetchData();
    }
  }, [dispatch, dataFetched, fetchData]);

  return (
    <Page title="Send Money">
      <Container maxWidth="xl">
        <FormProvider methods={methods} onSubmit={handleSubmit(handleSendMoney)}>
          <Card>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <CardHeader title={'Sender Information'} subheader={''} />
                <Stack spacing={3} sx={{ p: 3 }}>
                  <RHFTextField
                    label="You Send"
                    name="senderAmount"
                    type="number"
                    value={senderAmount}
                    onChange={(e) => {
                      setSenderAmount(e.target.value);
                      setValue('senderAmount', e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <RHFSelect
                            name="senderCurrency"
                            value={senderCurrency}
                            onChange={(e) => {
                              setSenderCurrency(e.target.value);
                              setValue('senderCurrency', e.target.value);
                            }}
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                          >
                            {currencyCodes?.map((option) => (
                              <MenuItem
                                key={option?.code}
                                value={option?.code}
                                sx={{
                                  mx: 1,
                                  my: 0.5,
                                  borderRadius: 0.75,
                                  typography: 'body2',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {option?.code}
                              </MenuItem>
                            ))}
                          </RHFSelect>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <RHFTextField
                    label="Recipient Gets"
                    name="receiverAmount"
                    type="number"
                    value={receiverAmount}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <RHFSelect
                            name="receiverCurrency"
                            value={receiverCurrency}
                            onChange={(e) => {
                              setReceiverCurrency(e.target.value);
                              setValue('receiverCurrency', e.target.value);
                            }}
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                          >
                            {currencyCodes?.map((option) => (
                              <MenuItem
                                key={option?.code}
                                value={option?.code}
                                sx={{
                                  mx: 1,
                                  my: 0.5,
                                  borderRadius: 0.75,
                                  typography: 'body2',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {option?.code}
                              </MenuItem>
                            ))}
                          </RHFSelect>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <RHFTextField
                      type="number"
                      name="receiverAmount"
                      label="Recipient Gets"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Autocomplete
                              options={currencyCodes}
                              getOptionLabel={(option) => option.code}
                              onChange={(event, newValue) => {
                                setReceiverCurrency(newValue?.code);
                                setValue('receiverCurrency', newValue?.code);
                              }}
                              renderInput={(params) => <TextField {...params} name="senderAmount" />}
                            />
                          </InputAdornment>
                        ),
                      }}
                    /> */}
                  {exchangeRates && (
                    <Typography variant="h3">
                      {1} {exchangeRates?.base || 'USD'} = {exchangeRates?.exchangeRate || 0}{' '}
                      {exchangeRates?.counter || 'KES'}
                    </Typography>
                  )}

                  <RHFSelect
                    name="incomeSource"
                    label="Source of Funds"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {incomeSources?.map((option) => (
                      <MenuItem
                        key={option?.incomeSourceCode}
                        value={option?.incomeSourceCode}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      >
                        {option?.incomeSourceName}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  <RHFSelect
                    name="fundPurpose"
                    label="Sending Purpose"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {fundPurposes?.map((option) => (
                      <MenuItem
                        key={option?.purposeCode}
                        value={option?.purposeCode}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      >
                        {option?.purposeName}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  <RHFTextField name="senderFirstName" label="Sender FirstName" />
                  {/* <RHFTextField name="senderMiddleName" label="Sender Middle Name" /> */}
                  <RHFTextField name="senderLastName" label="Sender Last Name" />
                  <RHFTextField name="senderDob" label="Sender Date of Birth" />
                  <RHFSelect
                    name="senderDocumentType"
                    label="Sender Document Type"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {documentTypes?.map((option) => (
                      <MenuItem
                        key={option?.documentType}
                        value={option?.documentType}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      >
                        {option?.documentName}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  <RHFTextField name="senderDocumentNumber" label="Sender Document Number" />
                  <RHFTextField name="senderCountryCode" label="Sender Country Code" />
                  <RHFTextField name="senderPhysicalAddress" label="Sender Physical Address" />
                  <RHFTextField type="email" name="senderEmail" label="Sender Email" />
                  <RHFTextField name="senderPhonenumber" label="Sender Phone Number" />
                  <RHFSelect
                    name="senderOccupation"
                    label="Sender Occupation"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {occupations?.map((option) => (
                      <MenuItem
                        key={option?.occupationCode}
                        value={option?.occupationCode}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      >
                        {option?.occupationName}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  <RHFTextField name="remarks" label="Remarks" />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CardHeader title={'Recipient Information'} subheader={''} />
                <Stack spacing={3} sx={{ p: 3 }}>
                  {/* <RHFSelect
                      name=""
                      label="Existings Recipient"
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                      onChange={(e) => {
                        setReceiverData(e.target.value);
                      }}
                    >
                      {customers?.map((option) => (
                        <MenuItem
                          key={option?.id}
                          value={option}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: 'body2',
                            textTransform: 'capitalize',
                          }}
                        >
                          {option?.receiver_first_name} {option?.receiver_last_name}|{option?.receiver_phone_number}
                        </MenuItem>
                      ))}
                    </RHFSelect> */}
                  <RHFTextField name="receiverCompanyName" label="Company Name" />
                  <RHFTextField name="receiverFirstName" label="First Name" />
                  <RHFTextField name="receiverLastName" label="Last Name" />
                  <RHFTextField name="receiverDob" label="Date of Birth" />

                  <RHFSelect
                    name="receiverGender"
                    label="Gender"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {GENDER?.map((option) => (
                      <MenuItem
                        key={option?.value}
                        value={option?.value}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      >
                        {option?.label}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  <RHFSelect
                    name="receiverDocumentType"
                    label="Document Type"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {documentTypes?.map((option) => (
                      <MenuItem
                        key={option?.documentType}
                        value={option?.documentType}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      >
                        {option?.documentName}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  <RHFTextField name="receiverDocumentNumber" label="Document Number" />
                  <RHFTextField name="receiverCountryCode" label="Country Code" />
                  <RHFTextField name="receiverPhysicalAddress" label="Physical Address" />
                  <RHFTextField type="email" name="receiverEmail" label="Email" />
                  <RHFTextField name="receiverPhonenumber" label="Phone Number" />
                  <RHFSelect
                    name="receiverOccupation"
                    label="Occupation"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {occupations?.map((option) => (
                      <MenuItem
                        key={option?.occupationCode}
                        value={option?.occupationCode}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      >
                        {option?.occupationName}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  <RHFSelect
                    name="receiverPaymentMode"
                    label="Payment Mode"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                    onChange={(e) => {
                      setPaymentMode(e.target.value);
                      setValue('receiverPaymentMode', e.target.value);
                    }}
                  >
                    {paymentModes?.map((option) => (
                      <MenuItem
                        key={option?.paymentModeCode}
                        value={option?.paymentModeCode}
                        sx={{
                          mx: 1,
                          my: 0.5,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize',
                        }}
                      >
                        {option?.paymentModeName}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  {paymentmode === 'MOBILE' && (
                    <RHFSelect
                      name="receiverBillerCode"
                      label="Select Provider"
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                    >
                      {momo?.map((option) => (
                        <MenuItem
                          key={option?.momoCode}
                          value={option?.momoCode}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: 'body2',
                            textTransform: 'capitalize',
                          }}
                        >
                          {option?.momoName}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  )}
                  {paymentmode === 'BANK' && (
                    <>
                      <RHFSelect
                        name=""
                        label="Bank"
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                      >
                        {banks?.map((option) => (
                          <MenuItem
                            key={option?.bankCode}
                            value={option?.bankCode}
                            sx={{
                              mx: 1,
                              my: 0.5,
                              borderRadius: 0.75,
                              typography: 'body2',
                              textTransform: 'capitalize',
                            }}
                          >
                            {option?.bankName}
                          </MenuItem>
                        ))}
                      </RHFSelect>
                      <RHFTextField name="receiverAccountName" label="Account Name" />
                      <RHFTextField name="receiverAccountNumber" label="Account Number" />
                    </>
                  )}
                  {paymentmode === 'BILL_PAYMENT' && <RHFTextField name="receiverBillerCode" label="Biller Number" />}
                </Stack>
                <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 3, p: 3 }}>
                  <LoadingButton
                    variant="contained"
                    size="large"
                    type="submit"
                    disabled={isSubmitting}
                    loading={isLoading}
                    endIcon={<SendIcon />}
                  >
                    Send Money
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Card>
        </FormProvider>
      </Container>
    </Page>
  );
}
