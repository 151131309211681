export default {
  GENERATE_ASSET_REPORTS: 'reports/assets-report/',
  GENERATE_MASTER_ASSET_REPORTS: 'reports/master-report/',
  GENERATE_BARCODE_REPORTS: 'reports/barcode-report/',
  GENERATE_BARCODE_NUMBER_REPORTS: 'reports/barcode-number-csv/',
  GENERATE_CUSTODIAN_REPORTS: 'reports/custodian-report/',
  GENERATE_CLASS_REPORTS: 'reports/class-report/',
  GENERATE_GAP_ANALYSIS_REPORTS: 'reports/gap-analysis-report/',
  GENERATE_DISPOSAL_REPORTS: 'reports/disposal-report/',
  GENERATE_MAINTENANCE_REPORTS: 'reports/maintenance-report/',
};
