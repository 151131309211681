export const GET_INVOICES_REQUEST = 'INVOICES/GET_INVOICES_REQUEST';
export const GET_INVOICES_SUCCESS = 'INVOICES/GET_INVOICES_SUCCESS';
export const GET_INVOICES_ERROR = 'INVOICES/GET_INVOICES_ERROR';
export const GET_TRANSACTIONS_REQUEST = 'INVOICES/GET_TRANSACTIONS_REQUEST';
export const GET_TRANSACTIONS_SUCCESS = 'INVOICES/GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_ERROR = 'INVOICES/GET_TRANSACTIONS_ERROR';
export const GET_INVOICE_REQUEST = 'INVOICES/GET_INVOICE_REQUEST';
export const GET_INVOICE_SUCCESS = 'INVOICES/GET_INVOICE_SUCCESS';
export const GET_INVOICE_ERROR = 'INVOICES/GET_INVOICE_ERROR';
export const GET_EXCHANGE_RATE_REQUEST = 'INVOICES/GET_EXCHANGE_RATE_REQUEST';
export const GET_EXCHANGE_RATE_SUCCESS = 'INVOICES/GET_EXCHANGE_RATE_SUCCESS';
export const GET_EXCHANGE_RATE_ERROR = 'INVOICES/GET_EXCHANGE_RATE_ERROR';
export const GET_INCOME_SOURCE_REQUEST = 'INVOICES/GET_INCOME_SOURCE_REQUEST';
export const GET_INCOME_SOURCE_SUCCESS = 'INVOICES/GET_INCOME_SOURCE_SUCCESS';
export const GET_INCOME_SOURCE_ERROR = 'INVOICES/GET_INCOME_SOURCE_ERROR';
export const GET_FUND_PURPOSES_REQUEST = 'INVOICES/GET_FUND_PURPOSES_REQUEST';
export const GET_FUND_PURPOSES_SUCCESS = 'INVOICES/GET_FUND_PURPOSES_SUCCESS';
export const GET_FUND_PURPOSES_ERROR = 'INVOICES/GET_FUND_PURPOSES_ERROR';
export const GET_DOCUMENT_TYPES_REQUEST = 'INVOICES/GET_DOCUMENT_TYPES_REQUEST';
export const GET_DOCUMENT_TYPES_SUCCESS = 'INVOICES/GET_DOCUMENT_TYPES_SUCCESS';
export const GET_DOCUMENT_TYPES_ERROR = 'INVOICES/GET_DOCUMENT_TYPES_ERROR';
export const GET_MOMO_REQUEST = 'INVOICES/GET_MOMO_REQUEST';
export const GET_MOMO_SUCCESS = 'INVOICES/GET_MOMO_SUCCESS';
export const GET_MOMO_ERROR = 'INVOICES/GET_MOMO_ERROR';
export const GET_OCCUPATIONS_REQUEST = 'INVOICES/GET_OCCUPATIONS_REQUEST';
export const GET_OCCUPATIONS_SUCCESS = 'INVOICES/GET_OCCUPATIONS_SUCCESS';
export const GET_OCCUPATIONS_ERROR = 'INVOICES/GET_OCCUPATIONS_ERROR';
export const GET_PAYMENT_MODES_REQUEST = 'INVOICES/GET_PAYMENT_MODES_REQUEST';
export const GET_PAYMENT_MODES_SUCCESS = 'INVOICES/GET_PAYMENT_MODES_SUCCESS';
export const GET_PAYMENT_MODES_ERROR = 'INVOICES/GET_PAYMENT_MODES_ERROR';
export const GET_BANKS_REQUEST = 'INVOICES/GET_BANKS_REQUEST';
export const GET_BANKS_SUCCESS = 'INVOICES/GET_BANKS_SUCCESS';
export const GET_BANKS_ERROR = 'INVOICES/GET_BANKS_ERROR';
export const GET_RELATIONSHIPS_REQUEST = 'INVOICES/GET_RELATIONSHIPS_REQUEST';
export const GET_RELATIONSHIPS_SUCCESS = 'INVOICES/GET_RELATIONSHIPS_SUCCESS';
export const GET_RELATIONSHIPS_ERROR = 'INVOICES/GET_RELATIONSHIPS_ERROR';
export const GET_CUTOMERS_REQUEST = 'INVOICES/GET_CUTOMERS_REQUEST';
export const GET_CUTOMERS_SUCCESS = 'INVOICES/GET_CUTOMERS_SUCCESS';
export const GET_CUTOMERS_ERROR = 'INVOICES/GET_CUTOMERS_ERROR';
export const CREATE_INVOICE_REQUEST = 'INVOICES/CREATE_INVOICE_REQUEST';
export const CREATE_INVOICE_SUCCESS = 'INVOICES/CREATE_INVOICE_SUCCESS';
export const CREATE_INVOICE_ERROR = 'INVOICES/CREATE_INVOICE_ERROR';
export const SEND_MONEY_REQUEST = 'INVOICES/SEND_MONEY_REQUEST';
export const SEND_MONEY_SUCCESS = 'INVOICES/SEND_MONEY_SUCCESS';
export const SEND_MONEY_ERROR = 'INVOICES/SEND_MONEY_ERROR';
export const UPDATE_QUOTE_REQUEST = 'INVOICES/UPDATE_QUOTE_REQUEST';
export const UPDATE_QUOTE_SUCCESS = 'INVOICES/UPDATE_QUOTE_SUCCESS';
export const UPDATE_QUOTE_ERROR = 'INVOICES/UPDATE_QUOTE_ERROR';
export const UPDATE_QUOTE_STATUS_REQUEST = 'INVOICES/UPDATE_QUOTE_STATUS_REQUEST';
export const UPDATE_QUOTE_STATUS_SUCCESS = 'INVOICES/UPDATE_QUOTE_STATUS_SUCCESS';
export const UPDATE_QUOTE_STATUS_ERROR = 'INVOICES/UPDATE_QUOTE_STATUS_ERROR';
export const DELETE_QUOTE_REQUEST = 'INVOICES/DELETE_QUOTE_REQUEST';
export const DELETE_QUOTE_SUCCESS = 'INVOICES/DELETE_QUOTE_SUCCESS';
export const DELETE_QUOTE_ERROR = 'INVOICES/DELETE_QUOTE_ERROR';
export const REMOVE_QUOTE_ITEM_REQUEST = 'INVOICES/REMOVE_QUOTE_ITEM_REQUEST';
export const REMOVE_QUOTE_ITEM_SUCCESS = 'INVOICES/REMOVE_QUOTE_ITEM_SUCCESS';
export const REMOVE_QUOTE_ITEM_ERROR = 'INVOICES/REMOVE_QUOTE_ITEM_ERROR';
