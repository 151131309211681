import { toast } from 'react-toastify';

import {
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_ERROR,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_ERROR,
  GET_INVOICE_REQUEST,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_ERROR,
  GET_EXCHANGE_RATE_REQUEST,
  GET_EXCHANGE_RATE_SUCCESS,
  GET_EXCHANGE_RATE_ERROR,
  GET_INCOME_SOURCE_REQUEST,
  GET_INCOME_SOURCE_SUCCESS,
  GET_INCOME_SOURCE_ERROR,
  GET_FUND_PURPOSES_REQUEST,
  GET_FUND_PURPOSES_SUCCESS,
  GET_FUND_PURPOSES_ERROR,
  GET_DOCUMENT_TYPES_REQUEST,
  GET_DOCUMENT_TYPES_SUCCESS,
  GET_DOCUMENT_TYPES_ERROR,
  GET_MOMO_REQUEST,
  GET_MOMO_SUCCESS,
  GET_MOMO_ERROR,
  GET_OCCUPATIONS_REQUEST,
  GET_OCCUPATIONS_SUCCESS,
  GET_OCCUPATIONS_ERROR,
  GET_PAYMENT_MODES_REQUEST,
  GET_PAYMENT_MODES_SUCCESS,
  GET_PAYMENT_MODES_ERROR,
  GET_BANKS_REQUEST,
  GET_BANKS_SUCCESS,
  GET_BANKS_ERROR,
  GET_RELATIONSHIPS_REQUEST,
  GET_RELATIONSHIPS_SUCCESS,
  GET_RELATIONSHIPS_ERROR,
  GET_CUTOMERS_REQUEST,
  GET_CUTOMERS_SUCCESS,
  GET_CUTOMERS_ERROR,
  CREATE_INVOICE_REQUEST,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_ERROR,
  SEND_MONEY_REQUEST,
  SEND_MONEY_SUCCESS,
  SEND_MONEY_ERROR,
  UPDATE_QUOTE_REQUEST,
  UPDATE_QUOTE_SUCCESS,
  UPDATE_QUOTE_ERROR,
  UPDATE_QUOTE_STATUS_REQUEST,
  UPDATE_QUOTE_STATUS_SUCCESS,
  UPDATE_QUOTE_STATUS_ERROR,
  DELETE_QUOTE_REQUEST,
  DELETE_QUOTE_SUCCESS,
  DELETE_QUOTE_ERROR,
  REMOVE_QUOTE_ITEM_REQUEST,
  REMOVE_QUOTE_ITEM_SUCCESS,
  REMOVE_QUOTE_ITEM_ERROR,
} from './paymentActionTypes';
import call from '../../../core/services/http';
import paymentConstants from './paymentConstants';

const toastrSuccess = (msg) => {
  toast.success(msg);
};
const toastrError = (msg) => {
  toast.error(msg);
};

export const getInvoices = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_INVOICES_REQUEST,
      isLoading: true,
    });
    const response = await call('get', paymentConstants.GET_INVOICES(payload));
    if (response.data.status) {
      dispatch({
        type: GET_INVOICES_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_INVOICES_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (e) {
    dispatch({
      type: GET_INVOICES_ERROR,
      isLoading: false,
    });
    toastrError('Sorry, something went wrong');
  }
};
export const getTransactions = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TRANSACTIONS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', paymentConstants.GET_TRANSACTIONS(payload));
    if (response.data.status) {
      dispatch({
        type: GET_TRANSACTIONS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_TRANSACTIONS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_TRANSACTIONS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_TRANSACTIONS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const searchTransactions = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TRANSACTIONS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', paymentConstants.SEARCH_TRANSACTIONS(payload));
    if (response.data.status) {
      dispatch({
        type: GET_TRANSACTIONS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_TRANSACTIONS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_TRANSACTIONS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_TRANSACTIONS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const filterTransactions = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TRANSACTIONS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', paymentConstants.FILTER_TRANSACTIONS(payload));
    if (response.data.status) {
      dispatch({
        type: GET_TRANSACTIONS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_TRANSACTIONS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_TRANSACTIONS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_TRANSACTIONS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getInvoice = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_INVOICE_REQUEST,
      isLoading: true,
    });
    const response = await call('get', paymentConstants.GET_INVOICE(id));
    if (response.data.status) {
      dispatch({
        type: GET_INVOICE_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_INVOICE_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (e) {
    dispatch({
      type: GET_INVOICE_ERROR,
      isLoading: false,
    });
    toastrError('Sorry, something went wrong');
  }
};
export const getExchangeRates = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_EXCHANGE_RATE_REQUEST,
      isLoading: true,
    });
    const response = await call('get', paymentConstants.GET_EXCHANGE_RATES);
    if (response.data.status) {
      dispatch({
        type: GET_EXCHANGE_RATE_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_EXCHANGE_RATE_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_EXCHANGE_RATE_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_EXCHANGE_RATE_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getIncomeSources = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_INCOME_SOURCE_REQUEST,
      isLoading: true,
    });
    const response = await call('get', paymentConstants.GET_INCOME_SOURCES);
    if (response.data.status) {
      dispatch({
        type: GET_INCOME_SOURCE_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_INCOME_SOURCE_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_INCOME_SOURCE_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_INCOME_SOURCE_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getFundsPurposes = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_FUND_PURPOSES_REQUEST,
      isLoading: true,
    });
    const response = await call('get', paymentConstants.GET_FUND_PURPOSES);
    if (response.data.status) {
      dispatch({
        type: GET_FUND_PURPOSES_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_FUND_PURPOSES_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_INCOME_SOURCE_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_INCOME_SOURCE_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getDocumentTypes = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_DOCUMENT_TYPES_REQUEST,
      isLoading: true,
    });
    const response = await call('get', paymentConstants.GET_DOCUMENT_TYPES);
    if (response.data.status) {
      dispatch({
        type: GET_DOCUMENT_TYPES_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_DOCUMENT_TYPES_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_DOCUMENT_TYPES_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_DOCUMENT_TYPES_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getMobileMoneyProviders = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_MOMO_REQUEST,
      isLoading: true,
    });
    const response = await call('get', paymentConstants.GET_MOMO);
    if (response.data.status) {
      dispatch({
        type: GET_MOMO_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_MOMO_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_MOMO_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_MOMO_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getOccupations = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_OCCUPATIONS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', paymentConstants.GET_OCCUPATIONS);
    if (response.data.status) {
      dispatch({
        type: GET_OCCUPATIONS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_OCCUPATIONS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_OCCUPATIONS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_OCCUPATIONS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getPaymentModes = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_PAYMENT_MODES_REQUEST,
      isLoading: true,
    });
    const response = await call('get', paymentConstants.GET_PAYMENT_MODES);
    if (response.data.status) {
      dispatch({
        type: GET_PAYMENT_MODES_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_PAYMENT_MODES_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_PAYMENT_MODES_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_PAYMENT_MODES_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getBanks = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_BANKS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', paymentConstants.GET_BANKS);
    if (response.data.status) {
      dispatch({
        type: GET_BANKS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_BANKS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_BANKS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_BANKS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getRelationships = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_RELATIONSHIPS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', paymentConstants.GET_RELATIONSHIPS);
    if (response.data.status) {
      dispatch({
        type: GET_RELATIONSHIPS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_RELATIONSHIPS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_RELATIONSHIPS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_RELATIONSHIPS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

export const getCustomers = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CUTOMERS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', paymentConstants.GET_CUSTOMERS);
    if (response.data.status) {
      dispatch({
        type: GET_CUTOMERS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_CUTOMERS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_CUTOMERS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_CUTOMERS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

export const sendMoneyRequest = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_MONEY_REQUEST,
      isLoading: true,
      isSent: false,
    });
    const response = await call('post', paymentConstants.SEND_MONEY, payload);
    if (response.data.status) {
      dispatch({
        type: SEND_MONEY_SUCCESS,
        isLoading: false,
        isSent: true,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);

      dispatch({
        type: SEND_MONEY_ERROR,
        isLoading: false,
        isSent: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: SEND_MONEY_ERROR,
        isLoading: false,
        isSent: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: SEND_MONEY_ERROR,
        isLoading: false,
        isSent: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

// export const createInvoice = (payload) => async (dispatch) => {
//   try {
//     dispatch({
//       type: CREATE_INVOICE_REQUEST,
//       isLoading: true,
//       isSuccess: false,
//     });
//     const response = await call('post', paymentConstants.CREATE_INVOICE, payload);
//     if (response.data.status) {
//       dispatch({
//         type: CREATE_INVOICE_SUCCESS,
//         isLoading: false,
//         isSuccess: true,
//       });
//       toastrSuccess(response.data.message);
//     } else {
//       toastrError(response.data.message);

//       dispatch({
//         type: CREATE_INVOICE_ERROR,
//         isLoading: false,
//         isSuccess: false,
//         message: response.data.message,
//       });
//     }
//   } catch (error) {
//     console.log('ERROR:', error);
//     if (error.response && error.response.status === 400) {
//       // Handle 400 Bad Request error
//       const errorMessage =
//         error.response.data && error.response.data.message
//           ? error.response.data.message
//           : 'Sorry, something went wrong';
//       dispatch({
//         type: CREATE_INVOICE_ERROR,
//         isLoading: false,
//         isSuccess: false,
//         message: errorMessage,
//       });
//       toastrError(errorMessage);
//     } else {
//       // Handle other errors (e.g., network issues, server errors)
//       console.error('An error occurred:', error);
//       toastrError('Sorry, something went wrong');
//     }
//   }
// };

export const createInvoice = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_INVOICE_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('post', paymentConstants.CREATE_INVOICE, payload);
    if (response.data.status) {
      dispatch({
        type: CREATE_INVOICE_SUCCESS,
        isLoading: false,
        isSuccess: true,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);

      dispatch({
        type: CREATE_INVOICE_ERROR,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: CREATE_INVOICE_ERROR,
        isLoading: false,
        isSuccess: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

export const updateQuote =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      console.log('FINAL PAYLOAD:', payload);
      dispatch({
        type: UPDATE_QUOTE_REQUEST,
        isLoading: true,
        isSuccess: false,
      });
      const response = await call('put', paymentConstants.UPDATE_QUOTE(id), payload);
      if (response.data.status) {
        dispatch({
          type: UPDATE_QUOTE_SUCCESS,
          isLoading: false,
          isSuccess: true,
          message: response.data.message,
        });
        toastrSuccess(response.data.message);
      } else {
        toastrError(response.data.message);
        dispatch({
          type: UPDATE_QUOTE_ERROR,
          isLoading: false,
          isSuccess: false,
          message: response.data.message,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_QUOTE_ERROR,
        isLoading: false,
        isSuccess: false,
      });
      toastrError('Sorry, something went wrong');
    }
  };

export const updateQuoteStatus =
  ({ id, requestData }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_QUOTE_STATUS_REQUEST,
        isLoading: true,
        isSuccess: false,
      });
      const response = await call('post', paymentConstants.UPDATE_QUOTE_STATUS(id), requestData);
      if (response.data.status) {
        dispatch({
          type: UPDATE_QUOTE_STATUS_SUCCESS,
          isLoading: false,
          isSuccess: true,
          message: response.data.message,
        });
        toastrSuccess(response.data.message);
      } else {
        toastrError(response.data.message);
        dispatch({
          type: UPDATE_QUOTE_STATUS_ERROR,
          isLoading: false,
          isSuccess: false,
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log('ERROR:', error);
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_QUOTE_STATUS_ERROR,
          isLoading: false,
          isSuccess: false,
          // message: e.response,
        });
        toastrError(errorMessage);
      } else if (error.response && error.response.status === 404) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_QUOTE_STATUS_ERROR,
          isLoading: false,
          isSuccess: false,
          // message: e.response,
        });
        toastrError(errorMessage);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An error occurred:', error);
        toastrError('Sorry, something went wrong');
      }
    }
  };

export const deleteQuotation = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_QUOTE_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('delete', paymentConstants.DELETE_QUOTATION(id));
    if (response.data.status) {
      dispatch({
        type: DELETE_QUOTE_SUCCESS,
        isLoading: false,
        isSuccess: true,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: DELETE_QUOTE_ERROR,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (e) {
    dispatch({
      type: DELETE_QUOTE_ERROR,
      isLoading: false,
      isSuccess: false,
    });
    toastrError('Sorry, something went wrong');
  }
};

export const removeQuoteItem = (id) => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_QUOTE_ITEM_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('post', paymentConstants.REMOVE_QUOTE_ITEM(id));
    if (response.data.status) {
      dispatch({
        type: REMOVE_QUOTE_ITEM_SUCCESS,
        isLoading: false,
        isSuccess: true,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: REMOVE_QUOTE_ITEM_ERROR,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (e) {
    dispatch({
      type: REMOVE_QUOTE_ITEM_ERROR,
      isLoading: false,
      isSuccess: false,
    });
    toastrError('Sorry, something went wrong');
  }
};
