import { useState } from 'react';
// @mui
import {
  Card,
  Container,
  Typography,
  Grid,
  Box,
  // CardHeader,
  CardContent,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Divider,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Page } from '../../../components';
import { BarcodeReportForm, BranchReportForm, CategoryReportForm, StaffReportForm } from '../components';
import { generateAssetMasterReport } from '../components/MasterAssetReport';

// ----------------------------------------------------------------------

export default function Reports() {
  const [open, setOpen] = useState(true);
  // const [openTagging, setOpenTagging] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('');

  const handleMenuClick = (option) => {
    setSelectedMenu(option);
  };

  let formComponent;
  if (selectedMenu === 'branch') {
    formComponent = <BranchReportForm />;
  } else if (selectedMenu === 'category') {
    formComponent = <CategoryReportForm />;
  } else if (selectedMenu === 'staff') {
    formComponent = <StaffReportForm />;
  } else if (selectedMenu === 'barcodeNumbers') {
    formComponent = <BarcodeReportForm />;
  }

  const handleAssetClick = () => {
    setOpen(!open);
  };
  // const handleTaggingClick = () => {
  //   setOpenTagging(!openTagging);
  // };

  return (
    <>
      <Page title="Reports">
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <>
              <Grid item xs={12} md={12} lg={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} lg={4}>
                    <Card>
                      <Box sx={{ p: 2, textAlign: 'center' }}>
                        <List>
                          <ListItemButton onClick={handleAssetClick}>
                            <ListItemText>
                              <Typography variant="subtitle1">Reports</Typography>
                            </ListItemText>
                            {open ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Divider />
                          <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClick('branch')}>
                                <ListItemText primary="Workshop Reports" />
                              </ListItemButton>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClick('category')}>
                                <ListItemText primary="Sales Reports" />
                              </ListItemButton>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => generateAssetMasterReport()}>
                                <ListItemText primary="Customer Reports" />
                              </ListItemButton>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => generateAssetMasterReport()}>
                                <ListItemText primary="Mechanic Reports" />
                              </ListItemButton>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => generateAssetMasterReport()}>
                                <ListItemText primary="Stock Reports" />
                              </ListItemButton>{' '}
                              <ListItemButton sx={{ pl: 4 }} onClick={() => generateAssetMasterReport()}>
                                <ListItemText primary="Supplier Reports" />
                              </ListItemButton>
                            </List>
                          </Collapse>
                        </List>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    <Card>
                      <CardContent>{formComponent}</CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
