import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  POST_MARK_AS_READ_REQUEST,
  POST_MARK_AS_READ_SUCCESS,
  POST_MARK_AS_READ_FAILURE,
} from './notificationActionTypes';

const initialState = {
  isLoading: false,
  payload: {},
  message: '',
  notifications: [],
  pagination: {},
  totalUnRead: 0,
};

export default function notificationReducer(state = initialState, action) {
  const { type, payload, isLoading, message } = action;

  switch (type) {
    case GET_NOTIFICATIONS_REQUEST:
      return {
        isLoading,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        isLoading,
        notifications: payload.data,
        pagination: payload,
        totalUnRead: payload.count,
      };
    case GET_NOTIFICATIONS_FAILURE:
      return {
        isLoading,
        message,
      };
    case POST_MARK_AS_READ_REQUEST:
      return {
        isLoading,
      };
    case POST_MARK_AS_READ_SUCCESS:
      return {
        message,
      };
    case POST_MARK_AS_READ_FAILURE:
      return {
        message,
      };
    default: {
      return state;
    }
  }
}
