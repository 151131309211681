export const GET_TEAM_MEMBERS_REQUEST = 'TEAM_MEMBERS/GET_TEAM_MEMBERS_REQUEST';
export const GET_TEAM_MEMBERS_SUCCESS = 'TEAM_MEMBERS/GET_TEAM_MEMBERS_SUCCESS';
export const GET_TEAM_MEMBERS_FAILURE = 'TEAM_MEMBERS/GET_TEAM_MEMBERS_FAILURE';
export const GET_AUDIT_TEAM_MEMBERS_REQUEST = 'TEAM_MEMBERS/GET_AUDIT_TEAM_MEMBERS_REQUEST';
export const GET_AUDIT_TEAM_MEMBERS_SUCCESS = 'TEAM_MEMBERS/GET_AUDIT_TEAM_MEMBERS_SUCCESS';
export const GET_AUDIT_TEAM_MEMBERS_FAILURE = 'TEAM_MEMBERS/GET_AUDIT_TEAM_MEMBERS_FAILURE';
export const GET_TEAM_MEMBER_REQUEST = 'TEAM_MEMBERS/GET_TEAM_MEMBER_REQUEST';
export const GET_TEAM_MEMBER_SUCCESS = 'TEAM_MEMBERS/GET_TEAM_MEMBER_SUCCESS';
export const GET_TEAM_MEMBER_FAILURE = 'TEAM_MEMBERS/GET_TEAM_MEMBER_FAILURE';
export const CREATE_TEAM_MEMBER_REQUEST = 'TEAM_MEMBERS/CREATE_TEAM_MEMBER_REQUEST';
export const CREATE_TEAM_MEMBER_SUCCESS = 'TEAM_MEMBERS/CREATE_TEAM_MEMBER_SUCCESS';
export const CREATE_TEAM_MEMBER_FAILURE = 'TEAM_MEMBERS/CREATE_TEAM_MEMBER_FAILURE';
