// @mui
import { Badge, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';

// components
import { useSelector } from 'react-redux';
import Iconify from '../../../components/iconify';


// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { totalUnRead } = useSelector((state) => state.notificationReducer);


  return (
    <>
      {/* <Link style={{ textDecoration: 'none' }} component={Link} to="/dashboard/notifications"> */}
      <Link style={{ textDecoration: 'none' }}>
        <IconButton color="default" sx={{ width: 40, height: 40 }}>
          <Badge badgeContent={totalUnRead} color="error">
            <Iconify icon="eva:bell-fill" />
          </Badge>
        </IconButton>
      </Link>
    </>
  );
}
