import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Divider, Typography, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Label from '../../../components/label/Label';
import Iconify from '../../../components/iconify/Iconify';
import PaymentComplete from './PaymentComplete';
import { fCurrency } from '../../../utils/formatNumber';
// components

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(5),
  backgroundColor: theme.palette.background.neutral,
  borderRadius: Number(theme.shape.borderRadius) * 2,
}));

// ----------------------------------------------------------------------
PaymentSummary.propTypes = {
  invoice: PropTypes.object,
};

export default function PaymentSummary({ invoice }) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(!open);
  };
  return (
    <>
      <RootStyle>
        <Typography variant="subtitle1" sx={{ mb: 5 }}>
          Summary
        </Typography>

        <Stack spacing={2.5}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle2" component="p" sx={{ color: 'text.secondary' }}>
              Invoice Number
            </Typography>
            <Label color="error" variant="filled">
              {invoice && invoice?.invoice_number}
            </Label>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography component="p" variant="subtitle2" sx={{ color: 'text.secondary' }}>
              Customer
            </Typography>
            <Label color="primary" variant="filled">
              {invoice && invoice?.customer?.display_name}
            </Label>
          </Stack>

          <Stack direction="row" justifyContent="flex-end">
            <Typography sx={{ color: 'text.secondary' }}>Ksh</Typography>
            <Typography variant="h2" sx={{ mx: 1 }}>
              {invoice && fCurrency(invoice.total_amount)}
            </Typography>
          </Stack>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" component="p">
              Total Billed
            </Typography>
            <Typography variant="h6" component="p">
              Ksh. {invoice && fCurrency(invoice.total_amount)}
            </Typography>
          </Stack>

          <Divider sx={{ borderStyle: 'dashed', mb: 1 }} />
        </Stack>

        {/* <Typography variant="caption" sx={{ color: 'text.secondary', mt: 1 }}>
          * Plus applicable taxes
        </Typography> */}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ mt: 5, mb: 3 }}
          onClick={handleClose}
        >
          PAY
        </LoadingButton>

        <Stack alignItems="center" spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Iconify icon={'eva:shield-fill'} sx={{ width: 20, height: 20, color: 'primary.main' }} />
            <Typography variant="subtitle2">Secure payment platform</Typography>
          </Stack>
          <Typography variant="caption" sx={{ color: 'text.secondary', textAlign: 'center' }}>
            This is a secure 128-bit SSL encrypted payment
          </Typography>
        </Stack>
      </RootStyle>

      {open && <PaymentComplete open={open} handleClose={handleClose} />}
    </>
  );
}
