import * as yup from 'yup';

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

export const registerSchema = yup.object({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  email: yup.string().email('Please enter a valid email'),
  mobile_number: yup.number().positive().integer().required('Mobile number is required'),
  password: yup
    .string()
    .min(6)
    .matches(passwordRules, { message: 'Please create a stronger password' })
    .required('Password is required'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
  acceptedTos: yup.boolean().oneOf([true], 'Please accept the terms of service'),
});

export const loginSchema = yup.object({
  username: yup.string().required('Mobile number is required'),
  password: yup.string().required('Password is required'),
});