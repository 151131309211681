// import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import * as Yup from 'yup';
// @mui
import {
  Card,
  Stack,
  Button,
  MenuItem,
  Container,
  Typography,
  Grid,
  Menu,
  Box,
  CardHeader,
  CardContent,
  Divider,
  DialogContent,
  Dialog,
  DialogActions,
  DialogTitle,
  Skeleton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import styled from '@emotion/styled';
import { PDFViewer } from '@react-pdf/renderer';
// components

import { Page } from '../../../components';
import { fCurrency } from '../../../utils/formatNumber';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
import Scrollbar from '../../../components/scrollbar';
import { InvoicePDF, JobCardPDF } from '.';

const ASSET_CONDITIONS = [
  {
    id: 1,
    label: 'Excellent',
    value: 'EXCELLENT',
  },
  {
    id: 2,
    label: 'Broken',
    value: 'BROKEN',
  },
];
const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export default function JobDetail() {
  //
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isLoading, job } = useSelector((state) => state.jobReducer);
  const { customers } = useSelector((state) => state.customerReducer);

  //
  const [open, setOpen] = useState(false);
  const [openInvoicePdf, setOpenInvoicePdf] = useState(false);
  const [openJobCardPdf, setOpenJobCardPdf] = useState(false);
  const [openCheckout, setOpenCheckout] = useState(false);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const checkoutSchema = Yup.object().shape({
    staffId: Yup.number().positive().required('Staff is required'),
    condition: Yup.string().required('Condition is required'),
    description: Yup.string(),
  });

  const defaultValues = {
    staffId: '',
    condition: '',
    description: '',
  };

  const methods = useForm({
    resolver: yupResolver(checkoutSchema),
    defaultValues,
  });

  const {
    // reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const transferData = {
        staff_id: data.staffId,
        condition: data.condition,
        description: data.description,
      };
      const payload = {
        id,
        transferData,
      };
      console.log('PAYLOAD:', payload);
      // dispatch(transferAsset(payload));
      setOpenCheckout(false);
      // dispatch(getJobDetail(id));
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   // dispatch(getJobDetail(id));
  //   dispatch(getCustomers({}));
  // }, [dispatch, id]);

  return (
    <>
      <Page title="Job Detail">
        {/* Asset Checkout Dialog Starts */}
        <Dialog
          maxWidth="md"
          open={openCheckout}
          fullWidth
          keepMounted
          onClose={() => setOpenCheckout(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>Edit Job</DialogTitle>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <DialogContent dividers>
              <Stack spacing={2}>
                <RHFSelect
                  fullWidth
                  name="staffId"
                  label="Select staff"
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                >
                  {customers?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.display_name}
                    </MenuItem>
                  ))}
                </RHFSelect>
                <RHFSelect
                  fullWidth
                  name="condition"
                  label="Condition at Checkout"
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                >
                  {ASSET_CONDITIONS?.map((option) => (
                    <MenuItem key={option.id} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
                <RHFTextField name="description" label="Description" />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button size="small" variant="contained" color="error" onClick={() => setOpenCheckout(false)}>
                Cancel
              </Button>
              <LoadingButton type="submit" size="small" variant="contained" loading={isSubmitting}>
                Checkout
              </LoadingButton>
            </DialogActions>
          </FormProvider>
        </Dialog>
        {/* Asset Checkout Dialog Ends */}
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <Card>
                <Box elevation={3} sx={{ p: 2 }}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography paragraph variant="h6" sx={{ color: 'text.disabled' }}>
                      CUSTOMER
                    </Typography>
                  </Box>
                  <Divider />
                  <Typography variant="body2">Name: {job?.booking?.user?.display_name}</Typography>
                  <Typography variant="body2">Mobile Number: {job?.booking?.user?.mobile_number}</Typography>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Card>
                <Box elevation={3} sx={{ p: 2 }}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography paragraph variant="h6" sx={{ color: 'text.disabled' }}>
                      VEHICLE
                    </Typography>
                  </Box>
                  <Divider />
                  <Typography variant="body2">Make: {job?.booking?.vehicle?.brand}</Typography>
                  <Typography variant="body2">Model: {job?.booking?.vehicle?.model}</Typography>
                  <Typography variant="body2">Plate Number: {job?.booking?.vehicle?.plate_number}</Typography>
                  <Typography variant="body2">Color: {job?.booking?.vehicle?.color}</Typography>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Card>
                <Box sx={{ p: 2 }}>
                  <Stack direction="row" sx={{ float: 'right' }} spacing={1}>
                    <Button
                      variant="outlined"
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleOpen}
                      startIcon={<ArrowDropDownIcon />}
                    >
                      Actions
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={open}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={() => setOpenJobCardPdf(true)}>
                        <PrintOutlinedIcon sx={{ fontSize: 'small', mr: 1 }} />
                        Job Card
                      </MenuItem>
                      <MenuItem onClick={() => setOpenInvoicePdf(true)}>
                        <PrintOutlinedIcon sx={{ fontSize: 'small', mr: 1 }} />
                        Invoice
                      </MenuItem>
                    </Menu>
                  </Stack>
                </Box>
                <CardHeader title={'Job/Invoice'} subheader={''} />
                <Divider />
                <CardContent>
                  {isLoading ? (
                    <Grid container sx={{ justifyContent: 'center' }}>
                      <>
                        <Skeleton animation="pulse" variant="rectangular" height={10} />
                        <Skeleton animation="pulse" variant="rectangular" height={10} />
                        <Skeleton animation="pulse" variant="rectangular" height={10} />
                        <Skeleton animation="pulse" variant="rectangular" height={10} />
                      </>
                    </Grid>
                  ) : (
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Box>
                          <Box
                            sx={{
                              display: 'grid',
                              rowGap: 3,
                              columnGap: 2,
                              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                            }}
                          >
                            <TextField
                              name="bookingType"
                              label="Booking Type"
                              value={job?.booking?.order_type}
                              disabled
                            />
                            <TextField
                              name="department"
                              label="Department"
                              value={job?.booking?.department?.name}
                              disabled
                            />
                            <TextField
                              name="bookingDate"
                              label="Booking Date"
                              value={job?.booking?.booking_date}
                              disabled
                            />

                            <TextField
                              name="releaseDate"
                              label="Expected Release Date"
                              value={job?.booking?.due_date}
                              disabled
                            />
                            <TextField
                              name="description"
                              label="Description"
                              value={job?.booking?.description}
                              disabled
                            />
                            <TextField name="status" label="Status" value={job?.status} disabled />
                          </Box>
                          <Scrollbar>
                            <TableContainer sx={{ minWidth: 960 }}>
                              <Table>
                                <TableHead
                                  sx={{
                                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                                    '& th': { backgroundColor: 'transparent' },
                                  }}
                                >
                                  <TableRow>
                                    <TableCell width={40}>#</TableCell>
                                    <TableCell align="left">Service/Product</TableCell>
                                    <TableCell align="left">Qty</TableCell>
                                    <TableCell align="right">Unit price</TableCell>
                                    <TableCell align="right">Total</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {job?.booking &&
                                    job?.booking?.repairs?.map((row, index) => (
                                      <TableRow
                                        key={index}
                                        sx={{
                                          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                                        }}
                                      >
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell align="left">
                                          <Box sx={{ maxWidth: 560 }}>
                                            <Typography variant="subtitle2">{row.name}</Typography>
                                          </Box>
                                        </TableCell>
                                        <TableCell align="left">{row.quantity}</TableCell>
                                        <TableCell align="right">{fCurrency(row.unit_price)}</TableCell>
                                        <TableCell align="right">{fCurrency(row.total_price)}</TableCell>
                                      </TableRow>
                                    ))}

                                  <RowResultStyle>
                                    <TableCell colSpan={3} />
                                    <TableCell align="right">
                                      <Box sx={{ mt: 2 }} />
                                      <Typography>Subtotal</Typography>
                                    </TableCell>
                                    <TableCell align="right" width={120}>
                                      <Box sx={{ mt: 2 }} />
                                      <Typography>{fCurrency(job?.invoice?.total_amount)}</Typography>
                                    </TableCell>
                                  </RowResultStyle>

                                  <RowResultStyle>
                                    <TableCell colSpan={3} />
                                    <TableCell align="right">
                                      <Typography>Discount</Typography>
                                    </TableCell>
                                    <TableCell align="right" width={120}>
                                      <Typography sx={{ color: 'error.main' }}>
                                        {(job?.invoice && fCurrency(-job?.invoice?.discount)) || 0}
                                      </Typography>
                                    </TableCell>
                                  </RowResultStyle>

                                  <RowResultStyle>
                                    <TableCell colSpan={3} />
                                    <TableCell align="right">
                                      <Typography>Taxes</Typography>
                                    </TableCell>
                                    <TableCell align="right" width={120}>
                                      <Typography>{(job?.invoice && fCurrency(-job?.invoice?.taxes)) || 0}</Typography>
                                    </TableCell>
                                  </RowResultStyle>

                                  <RowResultStyle>
                                    <TableCell colSpan={3} />
                                    <TableCell align="right">
                                      <Typography variant="h6">Total(KES)</Typography>
                                    </TableCell>
                                    <TableCell align="right" width={140}>
                                      <Typography variant="h6">{fCurrency(job?.invoice?.total_amount)}</Typography>
                                    </TableCell>
                                  </RowResultStyle>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Scrollbar>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>
      <Dialog fullScreen open={openJobCardPdf}>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              zIndex: 9,
              padding: '12px !important',
              boxShadow: (theme) => theme.customShadows.z8,
            }}
          >
            <Button size="small" variant="contained" color="primary" onClick={() => setOpenJobCardPdf(false)}>
              Close
            </Button>
          </DialogActions>
          <Box sx={{ flexGrow: 1, height: '100%', overflow: 'hidden' }}>
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
              <JobCardPDF job={job} />
            </PDFViewer>
          </Box>
        </Box>
      </Dialog>
      <Dialog fullScreen open={openInvoicePdf}>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              zIndex: 9,
              padding: '12px !important',
              boxShadow: (theme) => theme.customShadows.z8,
            }}
          >
            <Button size="small" variant="contained" color="primary" onClick={() => setOpenInvoicePdf(false)}>
              Close
            </Button>
          </DialogActions>
          <Box sx={{ flexGrow: 1, height: '100%', overflow: 'hidden' }}>
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
              <InvoicePDF invoice={job} />
            </PDFViewer>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
