import { Navigate } from 'react-router-dom';
import DashboardLayout from '../../../layouts/dashboard/DashboardLayout';
import Dashboard from '../view/Dashboard';

import SimpleLayout from '../../../layouts/simple/SimpleLayout';
import Page404 from '../../../pages/Page404';
import AuthGuard from '../../../shared/auth/AuthGuard';
import Reports from '../../reports/view/Reports';

import Transactions from '../../payments/view/Transactions';
import SendMoney from '../../payments/view/SendMoney';

export const dashboardRoutes = [
  {
    path: '/dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      { element: <Navigate to="/dashboard/app" />, index: true },
      { path: 'app', element: <Dashboard /> },
      { path: 'send-money', element: <SendMoney /> },
      { path: 'transactions', element: <Transactions /> },
      { path: 'reports', element: <Reports /> },
    ],
  },
  {
    element: <SimpleLayout />,
    children: [
      { element: <Navigate to="/dashboard/app" />, index: true },
      { path: '404', element: <Page404 /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/dashboard" replace />,
  },
];
