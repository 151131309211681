import {
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_ERROR,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_ERROR,
  GET_INVOICE_REQUEST,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_ERROR,
  GET_EXCHANGE_RATE_REQUEST,
  GET_EXCHANGE_RATE_SUCCESS,
  GET_EXCHANGE_RATE_ERROR,
  GET_INCOME_SOURCE_REQUEST,
  GET_INCOME_SOURCE_SUCCESS,
  GET_INCOME_SOURCE_ERROR,
  GET_FUND_PURPOSES_REQUEST,
  GET_FUND_PURPOSES_SUCCESS,
  GET_FUND_PURPOSES_ERROR,
  GET_DOCUMENT_TYPES_REQUEST,
  GET_DOCUMENT_TYPES_SUCCESS,
  GET_DOCUMENT_TYPES_ERROR,
  GET_MOMO_REQUEST,
  GET_MOMO_SUCCESS,
  GET_MOMO_ERROR,
  GET_OCCUPATIONS_REQUEST,
  GET_OCCUPATIONS_SUCCESS,
  GET_OCCUPATIONS_ERROR,
  GET_PAYMENT_MODES_REQUEST,
  GET_PAYMENT_MODES_SUCCESS,
  GET_PAYMENT_MODES_ERROR,
  GET_BANKS_REQUEST,
  GET_BANKS_SUCCESS,
  GET_BANKS_ERROR,
  GET_RELATIONSHIPS_REQUEST,
  GET_RELATIONSHIPS_SUCCESS,
  GET_RELATIONSHIPS_ERROR,
  GET_CUTOMERS_REQUEST,
  GET_CUTOMERS_SUCCESS,
  GET_CUTOMERS_ERROR,
  CREATE_INVOICE_REQUEST,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_ERROR,
  SEND_MONEY_REQUEST,
  SEND_MONEY_SUCCESS,
  SEND_MONEY_ERROR,
  UPDATE_QUOTE_REQUEST,
  UPDATE_QUOTE_SUCCESS,
  UPDATE_QUOTE_ERROR,
  UPDATE_QUOTE_STATUS_REQUEST,
  UPDATE_QUOTE_STATUS_SUCCESS,
  UPDATE_QUOTE_STATUS_ERROR,
  DELETE_QUOTE_REQUEST,
  DELETE_QUOTE_SUCCESS,
  DELETE_QUOTE_ERROR,
  REMOVE_QUOTE_ITEM_REQUEST,
  REMOVE_QUOTE_ITEM_SUCCESS,
  REMOVE_QUOTE_ITEM_ERROR,
} from './paymentActionTypes';

const initialState = {
  isLoading: false,
  isSuccess: false,
  isSent: false,
  payload: {},
  message: '',
  paymentLinks: [],
  transactions: [],
  quotes: [],
  jobInvoices: [],
  incomeSources: [],
  fundPurposes: [],
  invoice: {},
  itemsCount: 0,
  exchangeRates: {},
  documentTypes: [],
  momo: [],
  occupations: [],
  paymentModes: [],
  banks: [],
  relationships: [],
  customers: [],
};

export default function paymentReducer(state = initialState, action) {
  const { type, payload, isLoading, isSuccess, isSent, message } = action;

  switch (type) {
    case GET_INVOICES_REQUEST:
      return {
        isLoading,
      };
    case GET_INVOICES_SUCCESS:
      return {
        isLoading,
        paymentLinks: payload.data,
        itemsCount: payload.count,
      };
    case GET_INVOICES_ERROR:
      return {
        isLoading,
        message,
      };
    case GET_TRANSACTIONS_REQUEST:
      return {
        isLoading,
      };
    case GET_TRANSACTIONS_SUCCESS:
      return {
        isLoading,
        transactions: payload.data,
        itemsCount: payload.count,
      };
    case GET_TRANSACTIONS_ERROR:
      return {
        isLoading,
        message,
      };
    case GET_INVOICE_REQUEST:
      return {
        isLoading,
      };
    case GET_INVOICE_SUCCESS:
      return {
        isLoading,
        invoice: payload.data,
      };
    case GET_INVOICE_ERROR:
      return {
        isLoading,
        message,
      };
    case GET_EXCHANGE_RATE_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case GET_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        isLoading,
        isSuccess,
        exchangeRates: payload.data[0],
      };
    case GET_EXCHANGE_RATE_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case GET_INCOME_SOURCE_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case GET_INCOME_SOURCE_SUCCESS:
      return {
        ...state,
        isLoading,
        isSuccess,
        incomeSources: payload.data,
      };
    case GET_INCOME_SOURCE_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case GET_FUND_PURPOSES_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case GET_FUND_PURPOSES_SUCCESS:
      return {
        ...state,
        isLoading,
        isSuccess,
        fundPurposes: payload.data,
      };
    case GET_FUND_PURPOSES_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case GET_DOCUMENT_TYPES_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case GET_DOCUMENT_TYPES_SUCCESS:
      return {
        ...state,
        isLoading,
        isSuccess,
        documentTypes: payload.data,
      };
    case GET_DOCUMENT_TYPES_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case GET_MOMO_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case GET_MOMO_SUCCESS:
      return {
        ...state,
        isLoading,
        isSuccess,
        momo: payload.data,
      };
    case GET_MOMO_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case GET_OCCUPATIONS_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case GET_OCCUPATIONS_SUCCESS:
      return {
        ...state,
        isLoading,
        isSuccess,
        occupations: payload.data,
      };
    case GET_OCCUPATIONS_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case GET_PAYMENT_MODES_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case GET_PAYMENT_MODES_SUCCESS:
      return {
        ...state,
        isLoading,
        isSuccess,
        paymentModes: payload.data,
      };
    case GET_PAYMENT_MODES_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case GET_BANKS_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case GET_BANKS_SUCCESS:
      return {
        ...state,
        isLoading,
        isSuccess,
        banks: payload.data,
      };
    case GET_BANKS_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case GET_RELATIONSHIPS_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case GET_RELATIONSHIPS_SUCCESS:
      return {
        ...state,
        isLoading,
        isSuccess,
        relationships: payload.data,
      };
    case GET_RELATIONSHIPS_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case GET_CUTOMERS_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case GET_CUTOMERS_SUCCESS:
      return {
        ...state,
        isLoading,
        isSuccess,
        customers: payload.data,
      };
    case GET_CUTOMERS_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case CREATE_INVOICE_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case CREATE_INVOICE_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case CREATE_INVOICE_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case SEND_MONEY_REQUEST:
      return {
        isLoading,
        isSent,
      };
    case SEND_MONEY_SUCCESS:
      return {
        isLoading,
        isSent,
        message,
      };
    case SEND_MONEY_ERROR:
      return {
        isLoading,
        isSent,
        message,
      };
    case UPDATE_QUOTE_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case UPDATE_QUOTE_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case UPDATE_QUOTE_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case UPDATE_QUOTE_STATUS_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case UPDATE_QUOTE_STATUS_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case UPDATE_QUOTE_STATUS_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case REMOVE_QUOTE_ITEM_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case REMOVE_QUOTE_ITEM_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case REMOVE_QUOTE_ITEM_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case DELETE_QUOTE_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case DELETE_QUOTE_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case DELETE_QUOTE_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    default: {
      return state;
    }
  }
}
