import jwtDecode from 'jwt-decode';

export const storeToken = (data) => {
  localStorage.setItem('authtoken', data);
};
export const storeProfile = (data) => {
  localStorage.setItem('profile', JSON.stringify(data));
};

export const userLogout = () => {
  localStorage.clear();
  window.location.replace('/login');
};
export const getToken = () => {
  // get token from the cookie
  const authString = localStorage.getItem('authtoken');
  switch (authString) {
    case '':
      return false;
    case null:
      return false;
    case 'undefined':
      return false;
    default:
      // return JSON.parse(authString);
      return authString;
  }
};

export const getProfile = () => {
  // get token from the cookie
  const authString = localStorage.getItem('profile');
  switch (authString) {
    case '':
      return false;
    case null:
      return false;
    case 'undefined':
      return false;
    default:
      return JSON.parse(authString);
  }
};

export const removeToken = () => {
  localStorage.removeItem('authtoken');
};

class AuthService {
  constructor() {
    this.authenticated = false;
    this.auth = {};
  }

  logout() {
    this.authenticated = false;
    userLogout();
  }

  isAuthenticated() {
    this.checkValidity();
    return this.authenticated;
  }

  checkValidity() {
    const token = getToken();
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded.exp > Date.now() / 1000) {
        this.authenticated = true;
      } else {
        removeToken();
        this.authenticated = false;

        // return false;
      }
    } else {
      removeToken();
      this.authenticated = false;
    }
  }
}

export default new AuthService();
