import * as yup from 'yup';

export const branchReportSchema = yup.object({
  locationId: yup.number().positive().integer().required('Branch is required'),
});

export const categoryReportSchema = yup.object({
  categoryId: yup.number().positive().integer().required('Category is required'),
});
export const staffReportSchema = yup.object({
  staffId: yup.number().positive().integer().required('Staff is required'),
});

