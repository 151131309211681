import { useEffect, useState } from 'react';
import { useFormik } from 'formik';

// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// components
import Iconify from '../../../components/iconify';
import { loginUser } from '../store/authActions';
import { loginSchema } from '../schemas/authSchemas';
import { useNotify } from '../../../hooks/useNotify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const dispatch = useDispatch();
  const { isLoading, isLoggedIn } = useSelector((state) => state.authReducer);

  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { notifySuccess } = useNotify();

  const onSubmit = async (values) => {
    dispatch(loginUser(values));
  };
  useEffect(() => {
    if (isLoggedIn) {
      notifySuccess('Login success!');
      navigate('/dashboard');
    }
  }, [dispatch, isLoggedIn, navigate, notifySuccess]);

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit,
  });
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            name="username"
            value={values.username}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(errors.username) && touched.username}
            label="Username"
          />
          {errors.username && touched.username && (
            <Typography color="error" variant="body2">
              {errors.username}
            </Typography>
          )}

          <TextField
            name="password"
            label="Password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={Boolean(errors.password) && touched.password}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors.password && touched.password && (
            <Typography color="error" variant="body2">
              {errors.password}
            </Typography>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel control={<Checkbox />} checked label="Remember me" />
          <Link variant="subtitle2" underline="hover">
            Forgot password?
          </Link>
        </Stack>
        <LoadingButton
          loading={isLoading}
          disabled={isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Login
        </LoadingButton>
      </form>
    </>
  );
}
