// import { useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Divider, Typography, Stack, Dialog } from '@mui/material';
// redux
// import { useDispatch } from '../../../../redux/store';
// import { resetCart } from '../../../../redux/slices/product';
// routes

// components
// assets
import { OrderCompleteIllustration } from '../../../assets';
import Iconify from '../../../components/iconify/Iconify';
import { DialogAnimate } from '../../../animate';

// ----------------------------------------------------------------------

const DialogStyle = styled(DialogAnimate)(({ theme }) => ({
  '& .MuiDialog-paper': {
    margin: 0,
    [theme.breakpoints.up('md')]: {
      maxWidth: 'calc(100% - 48px)',
      maxHeight: 'calc(100% - 48px)',
    },
  },
}));

// ----------------------------------------------------------------------
PaymentComplete.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function PaymentComplete({ open,handleClose }) {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  // const handleResetStep = () => {
  //   dispatch(resetCart());
  //   // navigate(PATH_DASHBOARD.eCommerce.shop);
  // };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ p: 4, maxWidth: 480, margin: 'auto' }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4" paragraph>
            Thank you, payment successful!
          </Typography>

          <OrderCompleteIllustration sx={{ height: 260, my: 10 }} />

          {/* <Typography align="left" paragraph>
            Thanks for placing order &nbsp;
            <Link href="#">01dc1370-3df6-11eb-b378-0242ac130002</Link>
          </Typography>

          <Typography align="left" sx={{ color: 'text.secondary' }}>
            We will send you a notification within 5 days when it ships.
            <br /> <br /> If you have any question or queries then fell to get in contact us. <br /> <br /> All the
            best,
          </Typography> */}
        </Box>

        <Divider sx={{ my: 3 }} />

        <Stack direction={{ xs: 'column-reverse', sm: 'row' }} justifyContent="space-between" spacing={2}>
          <Button color="inherit" onClick={handleClose} startIcon={<Iconify icon={'eva:arrow-ios-back-fill'} />}>
            Back
          </Button>
          <Button
            variant="contained"
            startIcon={<Iconify icon={'ant-design:file-pdf-filled'} />}
            onClick={handleClose}
          >
            Download as PDF
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
