import { GET_REPORT_REQUEST, GET_REPORT_SUCCESS, GET_REPORT_ERROR } from './reportActionTypes';

const initialState = {
  isLoading: false,
  isSuccess: false,
  // payload: {},
  message: '',
  // scannedAsset: {},
};

export default function reportReducer(state = initialState, action) {
  const { type, isLoading, message } = action;

  switch (type) {
    case GET_REPORT_REQUEST:
      return {
        isLoading,
      };
    case GET_REPORT_SUCCESS:
      return {
        isLoading,
        // scannedAsset: payload.data[0],
      };
    case GET_REPORT_ERROR:
      return {
        isLoading,
        message,
      };
    // case CREATE_ASSET_REQUEST:
    //   return {
    //     isLoading,
    //     isSuccess,
    //   };
    // case CREATE_ASSET_SUCCESS:
    //   return {
    //     isLoading,
    //     isSuccess,
    //     message,
    //   };
    // case CREATE_ASSET_ERROR:
    //   return {
    //     isLoading,
    //     isSuccess,
    //     message,
    //   };
    default: {
      return state;
    }
  }
}
