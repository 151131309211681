import {
  GET_TEAM_MEMBERS_REQUEST,
  GET_TEAM_MEMBERS_SUCCESS,
  GET_TEAM_MEMBERS_FAILURE,
  // GET_AUDIT_CUSTOMERS_REQUEST,
  // GET_AUDIT_CUSTOMERS_SUCCESS,
  // GET_AUDIT_CUSTOMERS_FAILURE,
  // GET_CUSTOMER_REQUEST,
  // GET_CUSTOMER_SUCCESS,
  // GET_CUSTOMER_FAILURE,
  CREATE_TEAM_MEMBER_REQUEST,
  CREATE_TEAM_MEMBER_SUCCESS,
  CREATE_TEAM_MEMBER_FAILURE,
} from './teamActionTypes';

const initialState = {
  isLoading: false,
  isSuccess: false,
  payload: {},
  message: '',
  teamMembers: [],
  teamMember: {},
  auditCustomers: [],
  itemsCount: 0,
};

export default function teamReducer(state = initialState, action) {
  const { type, payload, isLoading, isSuccess, message } = action;

  switch (type) {
    case GET_TEAM_MEMBERS_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case GET_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        isLoading,
        isSuccess,
        teamMembers: payload.data,
        itemsCount: payload.count,
      };
    case GET_TEAM_MEMBERS_FAILURE:
      return {
        isLoading,
        isSuccess,
        message,
      };
    // case GET_AUDIT_CUSTOMERS_REQUEST:
    //   return {
    //     isLoading,
    //     isSuccess,
    //   };
    // case GET_AUDIT_CUSTOMERS_SUCCESS:
    //   return {
    //     isLoading,
    //     isSuccess,
    //     auditCustomers: payload.data,
    //   };
    // case GET_AUDIT_CUSTOMERS_FAILURE:
    //   return {
    //     isLoading,
    //     isSuccess,
    //     message,
    //   };
    // case GET_CUSTOMER_REQUEST:
    //   return {
    //     isLoading,
    //     isSuccess,
    //   };
    // case GET_CUSTOMER_SUCCESS:
    //   return {
    //     isLoading,
    //     isSuccess,
    //     message,
    //     customer: payload.data,
    //   };

    // case GET_CUSTOMER_FAILURE:
    //   return {
    //     isLoading,
    //     isSuccess,
    //     message,
    //   };
    case CREATE_TEAM_MEMBER_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case CREATE_TEAM_MEMBER_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };

    case CREATE_TEAM_MEMBER_FAILURE:
      return {
        isLoading,
        isSuccess,
        message,
      };
    default: {
      return state;
    }
  }
}
