// import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import SearchIcon from '@mui/icons-material/Search';
// @mui
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Grid,
  Box,
  CardHeader,
  CardContent,
  Paper,
  Dialog,
  DialogActions,
  Skeleton,
  // MenuItem,
  // IconButton,
  // Menu,
  Stack,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
// components
import Label from '../../../components/label';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead } from '../../../sections/@dashboard/user';

import { Page } from '../../../components';
import { fCurrency, fNumber } from '../../../utils/formatNumber';
import { getTransactions, searchTransactions } from '../store/paymentActions';
import { fDateTime } from '../../../utils/formatTime';
import { InvoicePDF, TransactionFilter } from '../components';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'transactionCode', label: 'Transaction Code', alignRight: false },
  { id: 'senderAmount', label: 'Sender Amount', alignRight: false },
  { id: 'receiverAmount', label: 'Receiver Amount', alignRight: false },
  { id: 'senderCurrency', label: 'Sender Currency', alignRight: false },
  { id: 'receiverCurrency', label: 'Receiver Currency', alignRight: false },
  { id: 'refernce', label: 'Reference', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  // { id: '' },
];

export default function Transactions() {
  const dispatch = useDispatch();
  const { isLoading, transactions, itemsCount } = useSelector((state) => state.paymentReducer);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // menu

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (searchTerm) => {
    if (searchTerm) {
      const payload = {
        page: page + 1,
        rowsPerPage,
        searchTerm,
      };
      dispatch(searchTransactions(payload));
    }
  };

  const handleSearchTermChange = (e) => {
    const newSearchTerm = e.target.value;
    if (searchTerm && !newSearchTerm) {
      const payload = {
        page: page + 1,
        rowsPerPage,
      };
      dispatch(getTransactions(payload));
    }

    setSearchTerm(newSearchTerm);
  };

  useEffect(() => {
    const payload = {
      page: page + 1,
      rowsPerPage,
    };
    dispatch(getTransactions(payload));
  }, [dispatch, page, rowsPerPage]);

  return (
    <>
      <Page title="Transactions">
        {open && <TransactionFilter open={open} handleClose={handleClose} />}
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Card>
                <Box sx={{ p: 2 }}>
                  <Stack direction="row" sx={{ float: 'right' }} spacing={1}>
                    <Button
                      size="small"
                      variant="contained"
                      color="warning"
                      onClick={() => setOpen(true)}
                      startIcon={<FilterListOutlinedIcon />}
                    >
                      Advanced Filter
                    </Button>
                    <Button size="small" variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                      Export
                    </Button>
                  </Stack>
                </Box>
                <Stack spacing={2}>
                  <Stack sx={{ px: 2, mt: 3 }} spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                    <TextField
                      sx={{ minWidth: 330 }}
                      autoFocus
                      value={searchTerm}
                      onChange={handleSearchTermChange}
                      placeholder="Search"
                    />
                    <LoadingButton
                      variant="contained"
                      color="success"
                      startIcon={<SearchIcon />}
                      sx={{ minWidth: 'auto' }}
                      onClick={() => handleSearch(searchTerm)}
                    >
                      Search
                    </LoadingButton>
                  </Stack>
                  <CardHeader title={'Transactions'} subheader={''} />
                </Stack>
                <CardContent>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }} component={Paper}>
                      {isLoading ? (
                        <Grid container sx={{ justifyContent: 'center' }}>
                          <>
                            <Skeleton animation="pulse" variant="rectangular" height={10} />
                            <Skeleton animation="pulse" variant="rectangular" height={10} />
                            <Skeleton animation="pulse" variant="rectangular" height={10} />
                            <Skeleton animation="pulse" variant="rectangular" height={10} />
                          </>
                        </Grid>
                      ) : (
                        <Table size="small">
                          <UserListHead headLabel={TABLE_HEAD} rowCount={transactions?.length} />
                          <TableBody>
                            {transactions?.length > 0 ? (
                              transactions.map((record) => (
                                <TableRow hover key={record?.id} tabIndex={-1} role="checkbox">
                                  <TableCell align="left">{fDateTime(record?.date_created)}</TableCell>
                                  <TableCell align="left">{record?.transaction_code}</TableCell>
                                  <TableCell align="left">{fCurrency(record?.sender_amount)}</TableCell>
                                  <TableCell align="left">{fCurrency(record?.receiver_amount)}</TableCell>
                                  <TableCell align="left">{record?.sender_currency_code}</TableCell>
                                  <TableCell align="left">{record?.receiver_currency_code}</TableCell>
                                  <TableCell align="left">{record?.reference}</TableCell>
                                  <TableCell align="left">
                                    <Label color={(record?.result_code === '2' && 'success') || 'error'}>
                                      {(record?.result_code === '2' && 'Success') || 'Failed'}
                                    </Label>
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <>Transactions will appear here</>
                            )}
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={fNumber(itemsCount)}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
